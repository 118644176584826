import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import AppContainer from '../AppContainer'
import TitleContent from '../TitleContent'
import NormalNGrid from '../NormalNGrid'

interface ISlice {
  items: {
    image: any
    title: {
      richText: any
    }
    content: {
      richText: any
    }
    background_color1: any
    title_color: any
    text_color: any
  }[]
  primary: {
    title: {
      richText: any
    }
  }
}

const NormalCardGridSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  return (
    <TitleContent title={slice.primary?.title?.richText || ''}>
      <AppContainer largeContainer={true} animated={false}>
        <NormalNGrid
          cardsInfo={slice.items.map((item) => ({
            image: item.image.gatsbyImageData,
            alt: item.image.alt,
            title: item.title.richText,
            titleColor: item.title_color,
            backgroundColor: item.background_color1,
            description: item.content.richText,
            descriptionColor: item.text_color,
            arrowColor: item.title_color,
          }))}
        ></NormalNGrid>
      </AppContainer>
    </TitleContent>
  )
}

export default NormalCardGridSlice
