import React from 'react'
import RectangularCard from './cards/RectangularCard'
import AppContainer from './AppContainer'
import styled from 'styled-components'
import Slides from './Animations'

interface IProps {
  className?: string
  titles: string[]
  titleColors: string[]
  backgroundColors: string[]
  descriptions: string[]
  descriptionColors: string[]
  images: any[]
  links: any[]
  linkTexts: string[]
}

const CornerStoneGrid: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledAppContainer
      className={props.className}
      withMobilePadding={false}
      animated={false}
    >
      {props.titles.map((title, index) => (
        <Slides key={'corner-stone-card-' + index}>
          <RectangularCard
            image={props.images[index]}
            title={title}
            titleColor={props.titleColors[index]}
            backgroundColor={props.backgroundColors[index]}
            left={index % 2 == 0 ? true : false}
            description={props.descriptions[index]}
            descriptionColor={props.descriptionColors[index]}
            bottomLink={props.links[index]}
            bottomLinkText={props.linkTexts[index]}
          ></RectangularCard>
        </Slides>
      ))}
    </StyledAppContainer>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-top: 160px;
`

export default CornerStoneGrid
