import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import { mqFrom } from '../styles/responsive'
import AppContainer from './AppContainer'

import LargeImageCard from './cards/LargeImageCard'
import { GridCol, GridRow } from './grid'
import { Icon } from './icon'
import PageLink from './PageLink'
import TitleContent from './TitleContent'

export interface ICard {
  image: any
  title: any
  text: string
  link?: any
  alt: string
  firstPublicationDate?: string
}
interface IProps {
  cards: ICard[]
  title: any
}

const ArrowSlider = ({ cards, title }: IProps) => {
  const scrollableContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>()

  const [scrollCounter, setScrollCounter] = useState<number>(0)
  const maxScrollSteps = Math.floor((cards.length - 1) / 3)

  const scrollStories = () => {
    if (scrollableContainerRef.current) {
      const scrollWidth =
        scrollableContainerRef.current.clientWidth -
        (scrollableContainerRef.current.children?.[0]?.clientWidth || 350)

      scrollableContainerRef.current.scrollTo({
        left: scrollWidth * scrollCounter,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    scrollStories()
  }, [scrollCounter])

  useEffect(() => {
    window.addEventListener('resize', scrollStories)

    return () => {
      window.removeEventListener('resize', scrollStories)
    }
  })

  const items = cards.map((card, i) => (
    <GridCol l={4} m={3} s={3} key={'arrow-image-' + i}>
      <PageLink link={card.link}>
        <LargeImageCard
          image={card.image}
          title={card.title}
          description={card.text}
          alt={card.alt}
        ></LargeImageCard>
      </PageLink>
    </GridCol>
  ))

  return (
    <TitleContent title={title}>
      <AppContainer largeContainer={true}>
        <Wrapper>
          <List>
            <StyledGridRow ref={scrollableContainerRef}>
              {items.map((item) => (
                <>{item}</>
              ))}
            </StyledGridRow>
          </List>
          <Arrows>
            <StyledIcon
              disabled={scrollCounter === 0}
              type="ArrowLeft"
              size={100}
              color={colors.grey}
              onClick={() => setScrollCounter(Math.max(scrollCounter - 1, 0))}
            ></StyledIcon>
            <StyledIcon
              disabled={scrollCounter >= maxScrollSteps}
              type="ArrowRight"
              size={100}
              color={colors.grey}
              onClick={() =>
                setScrollCounter(Math.min(maxScrollSteps, scrollCounter + 1))
              }
            ></StyledIcon>
          </Arrows>
        </Wrapper>
      </AppContainer>
    </TitleContent>
  )
}

const Wrapper = styled.div`
  margin-left: 6.25vw;
  width: auto;
`

const List = styled.div`
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
`

const StyledGridRow = styled(GridRow)`
  flex-wrap: nowrap;
  > div {
    margin-right: 40px;
  }
  > div:nth-child(2n) {
    margin-top: 80px;
  }
  overflow: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  ${mqFrom.S`
    overflow: hidden;
  `}
`

const Arrows = styled.div`
  display: none;
  width: 100%;
  margin-top: 40px;
  justify-content: flex-end;
  width: 100%;
  padding-right: 80px;
  ${mqFrom.S`
    display: flex;
  `}
`

const StyledIcon = styled(Icon)<{ disabled: boolean }>`
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  &:last-child {
    margin-left: 56px;
  }
`

export default ArrowSlider
