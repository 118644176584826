import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { mqFrom } from '../styles/responsive'
import { applyTextStyle } from '../styles/typography'
import AppContainer from './AppContainer'
import { GridCol, GridRow } from './grid'
import PageTitle from './PageTitle'
import RichText from './RichText'
import Button from './Button'
import PageLink from './PageLink'

interface IProps {
  title: any
  titleColor: string
  description: string
  image?: {
    url?: any
    gatsbyImageData: any
    alt?: any
  }
  descriptionLink?: any
  descriptionLinkText?: string
}

const PageHeader = (props: IProps) => {
  return (
    <>
      {!props.image?.url ? (
        <StyledAppContainer
          withMobilePadding={true}
          animated={false}
          largeContainer={true}
        >
          <GridRow>
            <GridCol l={8} m={8} s={4}>
              <Title color={props.titleColor} field={props.title}></Title>
            </GridCol>
          </GridRow>
          <GridRow>
            <GridCol l={8} m={2} s={4}></GridCol>
            <GridCol l={8} m={6} s={4}>
              <Description>
                <RichText field={props.description} />
                {props.descriptionLink.url !== null && (
                  <PageLink link={props.descriptionLink}>
                    <StyledButton type="medium">
                      {props.descriptionLinkText}
                    </StyledButton>
                  </PageLink>
                )}
              </Description>
            </GridCol>
          </GridRow>
        </StyledAppContainer>
      ) : (
        <>
          <GridRow>
            <HeaderContentCol l={8} m={5} s={4}>
              <TextContentBox>
                <div>
                  <TitleSmall
                    color={props.titleColor}
                    field={props.title}
                  ></TitleSmall>
                  <Description>
                    <RichText field={props.description} />
                    {props.descriptionLink.url !== null && (
                      <PageLink link={props.descriptionLink}>
                        <StyledButton type="medium">
                          {props.descriptionLinkText}
                        </StyledButton>
                      </PageLink>
                    )}
                  </Description>
                </div>
              </TextContentBox>
            </HeaderContentCol>
            <ImageCol l={8} m={3} s={4}>
              <GatsbyImage
                image={props.image.gatsbyImageData}
                alt={props.image.alt ?? 'Page cover image'}
                objectFit="cover"
                style={{
                  width: '100%',
                  height: '100%',
                  margin: '0',
                }}
              ></GatsbyImage>
            </ImageCol>
          </GridRow>
        </>
      )}
    </>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-bottom: 160px;
`

const Description = styled.div`
  ${applyTextStyle('large')};
  align-self: flex-end;
  margin-top: 40px;
`

const Title = styled(PageTitle)<{ color: string }>`
  ${applyTextStyle('titleLoud')};
  color: ${({ color }) => color};
`

const TextContentBox = styled.div`
  height: 100%;
  align-items: center;
  padding: 0 6.25vw;
  padding-top: 30px;
  padding-bottom: 30px;
  ${mqFrom.S`
    padding: 50px 80px 50px 6.25vw;
  `}
`

const HeaderContentCol = styled(GridCol)`
  order: 2;
`

const ImageCol = styled(GridCol)`
  order: 1;
  ${mqFrom.S`
    order: 3;
  `}
`

const TitleSmall = styled(PageTitle)<{ color: string }>`
  ${applyTextStyle('titleNormal')};
  ${mqFrom.XL`
    ${applyTextStyle('titleLarge')};
  `}
  color: ${({ color }) => color};
`

const StyledButton = styled(Button)`
  margin-top: 24px;
`

export default PageHeader
