let scrollPosition = 0
let $enabled = false

export default {
  enable() {
    const $body = document && document.querySelector('body')
    scrollPosition = window.pageYOffset
    if (!$body) return
    $body.style.overflow = 'hidden'
    $body.style.position = 'fixed'
    $body.style.top = `-${scrollPosition}px`
    $body.style.width = '100%'
    $enabled = true
  },
  disable() {
    const $body = document && document.querySelector('body')
    if (!$body || !$enabled) return
    $body.style.removeProperty('overflow')
    $body.style.removeProperty('position')
    $body.style.removeProperty('top')
    $body.style.removeProperty('width')
    window.scrollTo(0, scrollPosition)
    $enabled = false
  },
}
