import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import PointsCard from '../cards/PointsCard'
import { IPrismicLink } from '../PageLink'

interface ISlice {
  items: {
    point: string
  }[]
  primary: {
    button_actual_link: IPrismicLink
    button_text: string
    image: any
    title: {
      richText: any
    }
  }
}

const PointsCardSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const points = slice.items.map((item) => item.point)

  return (
    <PointsCard
      image={slice.primary.image}
      title={slice.primary.title.richText}
      points={points}
      arrowColor={'brightBlue'}
      arrowText={slice.primary.button_text}
      arrowLink={slice.primary.button_actual_link}
    ></PointsCard>
  )
}

export default PointsCardSlice
