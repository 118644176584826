import { SliceComponentProps } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { mqFrom } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import AppContainer from '../AppContainer'
import Button from '../Button'
import { GridCol, GridRow } from '../grid'
import PageLink from '../PageLink'
import PageTitle from '../PageTitle'
import RichText from '../RichText'

interface ISlice {
  primary: {
    background: any
    logo: any
    header_card_arrow_label: string
    header_card_description: any
    header_card_link: any
    header_card_title: {
      richText: string
    }
  }
}

const HomeHeader = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  return (
    <Wrapper largeContainer={true} animated={false}>
      <HeaderImages>
        <GatsbyImage
          image={slice.primary.background.gatsbyImageData}
          alt={slice.primary.background.alt ?? 'Website page header'}
          objectFit="cover"
          objectPosition="center"
          style={{ height: '90vh', width: '100%', minHeight: '600px' }}
          imgStyle={{ height: '90vh', width: '100%', minHeight: '600px' }}
        ></GatsbyImage>
        <Gradient></Gradient>
        <GatsbyLogoImage
          image={slice.primary.logo.gatsbyImageData}
          alt={slice.primary.logo.alt ?? 'Logo'}
          objectFit="fill"
          style={{
            position: 'absolute',
            width: '102%',
            maxWidth: '102vw',
            height: '35%',
            left: '-1vw',
          }}
          imgStyle={{ width: '102%', maxWidth: '102vw' }}
        ></GatsbyLogoImage>
      </HeaderImages>
      <CardWrapper>
        <GridRow>
          <GridCol l={8} m={6} s={4}>
            <PageLink link={slice.primary.header_card_link}>
              <IntroCard>
                <AppContainer withMobilePadding={true}>
                  <CardTitle
                    field={slice.primary.header_card_title.richText}
                  ></CardTitle>
                  <CardDescription>
                    <RichText
                      field={slice.primary.header_card_description.richText}
                    ></RichText>
                  </CardDescription>
                  {slice.primary.header_card_link && (
                    <StyledButton type="medium" textColor="black">
                      {slice.primary.header_card_arrow_label}
                    </StyledButton>
                  )}
                </AppContainer>
              </IntroCard>
            </PageLink>
          </GridCol>
        </GridRow>
      </CardWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(AppContainer)`
  width: 100%;
  height: 100vh;
  min-height: 900px;
  position: relative;
  margin: 0 auto;
`

const HeaderImages = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 10;
  background: transparent linear-gradient(180deg, #0000007a 0%, #00000000 100%)
    0% 0% no-repeat padding-box;
`

const CardWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`

const IntroCard = styled.div`
  background-color: ${colors.brightPink};
  overflow: hidden;
  width: 100%;
`

const CardTitle = styled(PageTitle)`
  ${applyTextStyle('titleSubtle', 'desktop')};
  color: ${colors.brown};
  margin-top: 56px;
  margin-bottom: 24px;
`

const CardDescription = styled.div`
  ${applyTextStyle('normal')};
  color: ${colors.brown};
`

const StyledButton = styled(Button)`
  margin-top: 24px;
`

const GatsbyLogoImage = styled(GatsbyImage)`
  top: 5%;
  ${mqFrom.S`
    top: 15%;
  `}
`

export default HomeHeader
