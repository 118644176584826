/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'

// Utils
import './src/styles/global.css'

// Styling
import CSSReset from './src/styles/reset'
import { ContextTemplate } from './src/context/AppContext'
// Setup

import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './linkResolver'
import PageTemplate from './src/pages/{PrismicPage.url}'
import { PrismicProvider } from "@prismicio/react";
import { Link } from 'gatsby'

import "./static/fonts/nexa-family/nexa.css"


/* eslint-disable react/prop-types */
/**
 * Styles do not need to be re-rendered and thus sit together with Providers
 * and other top-level components
 *
 * For more details, see: https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
 */
export const wrapRootElement = ({ element }) => {
  return (
    <PrismicProvider
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            linkResolver: linkResolver,
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            componentResolver: componentResolverFromMap({
              page: PageTemplate,
            }),
          },
        ]}
      >
        <CSSReset />
        <ContextTemplate>{element}</ContextTemplate>
      </PrismicPreviewProvider>
    </PrismicProvider>
  )
}

/**
 * Set up persistent UI elements that are not unmounted on page change
 *
 * For more details, see: https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
 */
export const wrapPageElement = ({ element }) => <>
  {element}
</>
