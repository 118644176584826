import React from 'react'
import styled from 'styled-components'
import IconArrowRight from './IconArrowRight'
import IconArrowLeft from './IconArrowLeft'
import IconInstagram from './IconInstagram'
import IconLinkedIn from './IconLinkedIn'
import IconDragButton from './IconDragButton'
import IconMagnifyingGlass from './IconMagnifyingGlass'
import IconAnalitics from './IconAnalytics'
import IconLoading from './IconLoading'
import IconLoadingArrow from './IconLoadingArrow'
import IconArrowUp from './IconArrowUp'
import IconArrowDown from './IconArrowDown'

export type IconType =
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowRight'
  | 'ArrowLeft'
  | 'Instagram'
  | 'LinkedIn'
  | 'DragButton'
  | 'MagnifyingGlass'
  | 'Loading'
  | 'LoadingArrow'
  | 'Analytics'

interface IProps {
  type: IconType
  size?: number
  width?: number
  height?: number
  color?: string
  secondaryColor?: string
  className?: string
  onClick?: () => void
}

interface IconProps extends IProps {
  color: string
  size: number
}

const Icon = (props: IconProps) => {
  const size = props.size !== undefined ? props.size : 13
  const width = props.width || size
  const height = props.height || size
  const color = props.color ? props.color : 'black'
  return (
    <IconWrapper
      className={props.className}
      onClick={props.onClick}
      width={width}
      height={height}
    >
      {props.type === 'ArrowUp' && (
        <IconArrowUp width={width} height={height} color={color} />
      )}
      {props.type === 'ArrowDown' && (
        <IconArrowDown width={width} height={height} color={color} />
      )}
      {props.type === 'ArrowRight' && (
        <IconArrowRight width={width} height={height} color={color} />
      )}
      {props.type === 'ArrowLeft' && (
        <IconArrowLeft width={width} height={height} color={color} />
      )}
      {props.type === 'Instagram' && (
        <IconInstagram width={width} height={height} color={color} />
      )}
      {props.type === 'LinkedIn' && (
        <IconLinkedIn width={width} height={height} color={color} />
      )}
      {props.type === 'DragButton' && (
        <IconDragButton width={width} height={height} color={color} />
      )}
      {props.type === 'MagnifyingGlass' && (
        <IconMagnifyingGlass width={width} height={height} color={color} />
      )}
      {props.type === 'Loading' && (
        <IconLoading width={width} height={height} color={color}></IconLoading>
      )}
      {props.type === 'LoadingArrow' && (
        <IconLoadingArrow
          width={width}
          height={height}
          color={color}
        ></IconLoadingArrow>
      )}
      {props.type === 'Analytics' && (
        <IconAnalitics
          width={width}
          height={height}
          color={color}
        ></IconAnalitics>
      )}
    </IconWrapper>
  )
}

export const IconWrapper = styled.span<Pick<IconProps, 'width' | 'height'>>`
  position: relative;
  display: inline-block;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;

    path {
      transition: fill 0.3s ease;
    }
  }
`

export default Icon
