import { SliceComponentProps } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import AppContainer from '../AppContainer'

interface ISlice {
  primary: {
    image: any
    fit: 'Full Screen' | 'Container'
  }
}

const SimpleImage = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const image = (
    <GatsbyImage
      image={slice.primary.image.gatsbyImageData}
      alt={slice.primary.image.alt ?? 'Image Content'}
      style={{ width: '100%', height: 'auto' }}
      imgStyle={{ width: '100%', height: 'auto' }}
    ></GatsbyImage>
  )
  return (
    <>
      {slice.primary.fit === 'Container' && (
        <AppContainer>{image}</AppContainer>
      )}
      {slice.primary.fit === 'Full Screen' && image}
    </>
  )
}

export default SimpleImage
