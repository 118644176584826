import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import OurStory from '../OurStory'
import TitleContent from '../TitleContent'

interface ISlice {
  items: {
    background_color1: string
    description: {
      richText: string
    }
    image: any
    title?: {
      richText?: string
    }
  }[]
  primary: {
    title?: {
      richText?: any
    }
  }
}

const LargeCardsGridSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const cardsInfo = slice.items.map((item) => ({
    image: item.image,
    backgroundColor: item.background_color1,
    title: item?.title?.richText,
    description: item.description.richText,
  }))

  return (
    <TitleContent title={slice.primary?.title?.richText || ''}>
      <OurStory cardsInfo={cardsInfo}></OurStory>
    </TitleContent>
  )
}

export default LargeCardsGridSlice
