import { PrismicLink } from '@prismicio/react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import React from 'react'
import colors from '../styles/colors'

export interface IPrismicLink {
  id: any
  url: any
  link_type: any
}

interface IProps {
  children: React.ReactNode
  link?: IPrismicLink
}

const PageLink: React.FC<IProps> = ({ link, children }) => {
  return (
    <>
      {(!link || !link.link_type || !link.url) && <>{children}</>}
      {link && link.link_type === 'Document' ? (
        <AniLink cover to={link.url} bg={colors.yellow}>
          {children}
        </AniLink>
      ) : (
        <PrismicLink field={link}>{children}</PrismicLink>
      )}
    </>
  )
}

export default PageLink
