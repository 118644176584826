import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowUp = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 64 64"
  >
    <g transform="translate(10 20)">
      <path
        d="M7.5,23.622,31.5,6,55.5,23.622"
        transform="translate(-7.5 -6)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </g>
  </svg>
)

export default IconArrowUp
