import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import HomeHeader from '../components/slices/HomeHeader.slice'
import styled, { createGlobalStyle } from 'styled-components'
import GreenSlider from '../components/slices/GreenSlider.slice'
import Button from '../components/Button'
import Footer from '../components/Footer'
import { SliceComponentProps, SliceZone } from '@prismicio/react'
import colors from '../styles/colors'
import SimpleImage from '../components/slices/SimpleImage.slice'
import SimpleText from '../components/slices/SimpleText.slice'
import Quote from '../components/slices/Quote.slice'
import PointsCardSlice from '../components/slices/PointsCard.slice'
import RectangularCardsGridSlice from '../components/slices/RectangularCardsGrid.slice'
import ColoredArrowListSlice from '../components/slices/ColoredArrowList.slice'
import DropdownArrowListSlice from '../components/slices/DropdownArrowList.slice'
import LargeImageCardSlice from '../components/slices/LargeImageCard.slice'
import ArrowCardGridSlice from '../components/slices/ArrowCardGrid.slice'
import ImagesSlider from '../components/slices/ImagesSlider.slice'
import ServiceCardSlice from '../components/slices/ServiceCard.slice'
import CasesImageSliderSlice from '../components/slices/CasesImageSlider.slice'
import BlogpostSectionSlice from '../components/slices/BlogpostSection.slice'
import LargeCardsGridSlice from '../components/slices/LargeCardsGrid.slice'
import PageHeaderSlice from '../components/slices/PageHeader.slice'
import AppContainer from '../components/AppContainer'
import ContactFormSlice from '../components/slices/ContactForm.slice'
import DefaultLogo from '../images/DefaultLogo.svg'
import PinkThemeLogo from '../images/PinkThemeLogo.svg'
import BlackMagnifyingGlassLogo from '../images/BlackMagnifyingGlassLogo.svg'
import DarkBlueThemeLogo from '../images/DarkBlueThemeLogo.svg'
import DarkRedThemeLogo from '../images/DarkRedThemeLogo.svg'
import WhiteMagnifyingGlassLogo from '../images/WhiteMagnifyingGlassLogo.svg'
import Navigation from '../components/Navigation'
import { applyTextStyle } from '../styles/typography'
import PageLink from '../components/PageLink'
import config from 'react-reveal/globals'
import CasesGridSlice from '../components/slices/CasesGrid.slice'
import Seo from '../components/Seo'
import { getPageName } from '../utils/api.helper'
import BreadcrumbSlice from '../components/slices/Breadcrumb.slice'
import PageReusableContactFormSlice from '../components/slices/PageReusableContactFormSlice'
import NormalCardGridSlice from '../components/slices/NormalCardGrid.slice'
import ArrowButtonCardSlice from '../components/slices/ArrowButtonCard.slice'
import BlogOverviewDetailsSlice from '../components/slices/BlogOverviewDetails.slice'

type DataProps = {
  prismicPage: any
  prismicWebsiteConfig: any
  prismicPreviewOriginalData: any
}

export interface IPageTheme {
  backgroundColor: string
  textColor: string
  headersColor: string
  highlightsColor: string
  largeLinkColor?: string
  higlightsTextColor: string
  logo?: string
  lang?: string
}

const getPageTheme = (theme: string): IPageTheme => {
  switch (theme) {
    case 'Light Blue':
      return {
        backgroundColor: colors.lightBlue,
        textColor: colors.grey,
        headersColor: colors.matteRed,
        highlightsColor: colors.matteRed,
        higlightsTextColor: colors.white,
        logo: BlackMagnifyingGlassLogo,
      }
    case 'Dark Blue':
      return {
        backgroundColor: colors.brightBlue,
        textColor: colors.white,
        headersColor: colors.pink,
        highlightsColor: colors.pink,
        higlightsTextColor: colors.grey,
        logo: DarkBlueThemeLogo,
      }
    case 'Pink':
      return {
        backgroundColor: colors.pink,
        textColor: colors.grey,
        headersColor: colors.brightBlue,
        highlightsColor: colors.brightBlue,
        higlightsTextColor: colors.white,
        logo: PinkThemeLogo,
      }
    case 'Dark Red':
      return {
        backgroundColor: colors.bordeauxRed,
        textColor: colors.white,
        headersColor: colors.brightRed,
        highlightsColor: colors.brightRed,
        higlightsTextColor: colors.white,
        logo: DarkRedThemeLogo,
      }

    case 'Salmon':
      return {
        backgroundColor: colors.salmon,
        textColor: colors.grey,
        headersColor: colors.forrestGreen,
        highlightsColor: colors.forrestGreen,
        higlightsTextColor: colors.white,
        logo: BlackMagnifyingGlassLogo,
      }

    case 'FreshGreen':
      return {
        backgroundColor: colors.freshGreen,
        textColor: colors.grey,
        headersColor: colors.grey,
        highlightsColor: colors.grey,
        higlightsTextColor: colors.white,
        logo: BlackMagnifyingGlassLogo,
      }

    case 'Grey':
      return {
        backgroundColor: colors.grey,
        textColor: colors.white,
        headersColor: colors.freshGreen,
        highlightsColor: colors.freshGreen,
        higlightsTextColor: colors.white,
        logo: WhiteMagnifyingGlassLogo,
      }

    case 'MattePurple':
      return {
        backgroundColor: colors.mattePurple,
        textColor: colors.white,
        headersColor: colors.lightBlue,
        highlightsColor: colors.lightBlue,
        higlightsTextColor: colors.grey,
        logo: WhiteMagnifyingGlassLogo,
      }

    case 'Brown':
      return {
        backgroundColor: colors.brown,
        textColor: colors.white,
        headersColor: colors.yellow,
        highlightsColor: colors.yellow,
        higlightsTextColor: colors.brown,
        logo: WhiteMagnifyingGlassLogo,
      }

    default:
      return {
        backgroundColor: colors.sand,
        textColor: colors.grey,
        headersColor: colors.grey,
        highlightsColor: colors.yellow,
        largeLinkColor: colors.brightBlue,
        higlightsTextColor: colors.grey,
        logo: DefaultLogo,
      }
  }
}

const AbstractWebsitePage = ({
  data,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prismicPreviewOriginalData,
}: PageProps<DataProps>) => {
  const document = data.prismicPage.data
  const items = document.body
  const seoData = document.body1.length ? document.body1[0] : undefined

  const isHomePage = prismicPreviewOriginalData?.prismicPage.uid === 'index'

  // @TODO: this any will be removed in future when gatsby updates the TypeScript autogen
  // https://bytemeta.vip/repo/prismicio/prismic-reactjs/issues/144
  const sliceComponents: any = {
    page_header: PageHeaderSlice,
    home_header: HomeHeader,
    scrollable_slider: GreenSlider,
    large_link: LargeLink,
    cards_carousel: ArrowCardGridSlice,
    image: SimpleImage,
    points_card: PointsCardSlice,
    rectangular_cards_grid: RectangularCardsGridSlice,
    colored_arrow_list: ColoredArrowListSlice,
    dropdown_arrow_list: DropdownArrowListSlice,
    large_image_card: LargeImageCardSlice,
    images_slider: ImagesSlider,
    simple_text_: SimpleText,
    quote: Quote,
    service_card: ServiceCardSlice,
    cases_image_slider: CasesImageSliderSlice,
    blogpost_section: BlogpostSectionSlice,
    squareshapedgrid: LargeCardsGridSlice,
    contact_form1: ContactFormSlice,
    cases_grid: CasesGridSlice,
    breadcrumb: BreadcrumbSlice,
    page_reusable_contact_form: PageReusableContactFormSlice,
    normal_card_grid: NormalCardGridSlice,
    arrow_button_card: ArrowButtonCardSlice,
    blog_overview_details: BlogOverviewDetailsSlice,
  }

  const theme = {
    ...getPageTheme(document?.page_theme || 'Default'),
    lang: data.prismicPage.lang,
  }

  const GlobalStyle = createGlobalStyle`
    html, body {
      ${applyTextStyle('normal')};
      background: ${theme.backgroundColor};
      color: ${theme.textColor};
    }
    nav .selected-language {
      background-color: ${theme.highlightsColor};
      color: ${theme.higlightsTextColor};
    }
    a {
      color: ${theme.textColor};
    }
    :root {
      --site-text-color: ${theme.textColor};
      --page-background-color: ${theme.backgroundColor};
      --highlights-color: ${theme.highlightsColor};
      --highlights-text-color: ${theme.higlightsTextColor};
    }
    p {
      ${applyTextStyle('normal')};
    }
    h1 {
      ${applyTextStyle('titleLoud')};
    }
    h2 {
      ${applyTextStyle('titleLarge')};
    }
    h3 {
      ${applyTextStyle('titleLargeRegular')};
    }
    h4 {
      ${applyTextStyle('titleNormal')};
    }
    h5 {
      ${applyTextStyle('titleNormalRegular')};
    }
    h6 {
      ${applyTextStyle('titleSubtle')};
    }
  `

  config({ ssrFadeout: true })

  return (
    <>
      <Seo
        title={
          seoData?.primary?.meta_title ?? getPageName(data.prismicPage.uid)
        }
        description={seoData?.primary?.meta_description}
        keywords={seoData?.primary?.meta_keywords}
        image={seoData?.primary?.social_media_image?.url}
      ></Seo>
      <GlobalStyle></GlobalStyle>
      <Navigation
        lang={data.prismicPage.lang}
        altLang={data.prismicPage.alternate_languages[0]}
        theme={theme}
        isHomePage={isHomePage}
        currentPageSlug={prismicPreviewOriginalData?.prismicPage.uid || '///'}
      ></Navigation>
      <SliceZone slices={items} components={sliceComponents} context={theme} />
      <Footer logoColor={theme.textColor} lang={data.prismicPage.lang}></Footer>
    </>
  )
}

export const query = graphql`
  query ($lang: String, $id: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      uid
      lang
      alternate_languages {
        id
        type
        lang
        uid
        document {
          ... on PrismicPage {
            id
            type
            lang
            uid
            tags
          }
        }
      }
      data {
        page_theme
        body {
          ... on PrismicPageDataBodyCasesImageSlider {
            id
            slice_type
            primary {
              title1 {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyNormalCardGrid {
            id
            slice_type
            items {
              title_color
              title {
                richText
              }
              text_color
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              content {
                richText
              }
              background_color1
            }
            primary {
              title {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyBlogOverviewDetails {
            id
            slice_label
            slice_type
            primary {
              title {
                richText
              }
              description {
                richText
                text
              }
              custom_publish_date
            }
          }
          ... on PrismicPageDataBodyArrowButtonCard {
            id
            slice_type
            primary {
              title {
                richText
              }
              description {
                richText
              }
              bottom_link_text
              bottom_link {
                id
                url
                link_type
                target
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicPageDataBodyHomeHeader {
            id
            slice_type
            primary {
              background {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              header_card_arrow_label
              header_card_description {
                richText
              }
              header_card_title {
                richText
              }
              header_card_link {
                id
                url
                link_type
              }
              logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicPageDataBodyImagesSlider {
            id
            slice_type
            items {
              description {
                text
              }
              image {
                url
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
              }
            }
            primary {
              title {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyBreadcrumb {
            id
            slice_type
            primary {
              breadcrumb_level_one_label
              breadcrumb_level_one_link {
                id
                url
                link_type
                target
              }
              breadcrumb_level_two_label
              breadcrumb_level_two_link {
                id
                url
                link_type
                target
              }
              breadcrumb_level_three_label
              breadcrumb_level_three_link {
                id
                url
                link_type
                target
              }
              breadcrumb_level_four_label
              breadcrumb_level_four_link {
                id
                url
                link_type
                target
              }
            }
          }
          ... on PrismicPageDataBodyPageReusableContactForm {
            id
            slice_type
            primary {
              form_title {
                text
              }
              form_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              form_bottom_message {
                richText
              }
              form_button_label
              email_receiver
            }
            items {
              field_label
              field_type
            }
          }
          ... on PrismicPageDataBodyLargeLink {
            id
            slice_type
            primary {
              label
              page_link {
                id
                url
                link_type
                target
              }
            }
            slice_label
          }
          ... on PrismicPageDataBodyPageHeader {
            id
            slice_type
            primary {
              title {
                richText
              }
              header_image {
                url
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              description {
                richText
              }
              description_link {
                id
                url
                link_type
                target
              }
              description_link_text
            }
          }
          ... on PrismicPageDataBodyScrollableSlider {
            id
            slice_type
            items {
              video_link {
                id
                url
                link_type
              }
              title1 {
                richText
              }
              image {
                url
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              content {
                html
                raw
                richText
                text
              }
            }
            primary {
              title1 {
                richText
              }
              background_color
              text_color
            }
          }
          ... on PrismicPageDataBodySimpleText {
            id
            slice_type
            primary {
              bottom_arrow_label
              bottom_arrow_link {
                id
                url
                target
                link_type
              }
              content {
                richText
              }
              link_color
            }
          }
          ... on PrismicPageDataBodyImage {
            id
            slice_type
            primary {
              fit
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicPageDataBodyQuote {
            id
            slice_type
            primary {
              author
              quote_text {
                richText
              }
              author_avatar {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicPageDataBodyPointsCard {
            id
            slice_type
            items {
              point
            }
            primary {
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
              }
              button_actual_link {
                url
                id
                link_type
                target
              }
              button_text
            }
          }
          ... on PrismicPageDataBodyCardsCarousel {
            id
            slice_type
            items {
              title_color
              title {
                richText
              }
              text_color
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              content {
                richText
              }
              background_color1
              icon
              card_link {
                url
                id
                link_type
                target
              }
            }
            primary {
              title {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyRectangularCardsGrid {
            id
            slice_type
            items {
              bottom_link_text
              bottom_link {
                id
                url
                link_type
                target
              }
              card_title {
                richText
              }
              description {
                richText
              }
              description_color
              background_color1
              title_color
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
            primary {
              title {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyServiceCard {
            id
            slice_type
            items {
              link {
                url
                id
                link_type
                target
              }
              section_description {
                richText
              }
              section_title {
                richText
              }
            }
            primary {
              image_direction
              title {
                richText
              }
              description {
                richText
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicPageDataBodySquareshapedgrid {
            id
            slice_type
            primary {
              title {
                richText
              }
            }
            items {
              background_color1
              description {
                richText
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyBlogpostSection {
            id
            primary {
              title {
                text
              }
              work_at_wow_card_arrow_label
              work_at_wow_card_background_color
              work_at_wow_card_description {
                richText
              }
              work_at_wow_card_elements_color
              work_at_wow_card_image {
                gatsbyImageData(placeholder: BLURRED)
              }
              work_at_wow_card_page_link {
                id
                link_type
                target
                url
              }
              work_at_wow_card_title {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicPageDataBodyCasesGrid {
            id
            slice_label
            slice_type
            primary {
              title {
                text
              }
              work_at_wow_card_background_color
              work_at_wow_card_arrow_label
              work_at_wow_card_description {
                richText
              }
              work_at_wow_card_elements_color
              work_at_wow_card_image {
                gatsbyImageData(placeholder: BLURRED)
              }
              work_at_wow_card_page_link {
                id
                link_type
                url
                target
              }
              work_at_wow_card_title {
                text
              }
            }
          }
          ... on PrismicPageDataBodyColoredArrowList {
            id
            slice_label
            slice_type
            items {
              background_color1
              center_text {
                richText
                text
              }
              content_color
              left_text {
                richText
                text
              }
              link {
                id
                url
                link_type
                target
              }
            }
            primary {
              bottom_button_label
              title {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyDropdownArrowList {
            id
            slice_label
            slice_type
            items {
              background_color1
              center_text {
                richText
                text
              }
              content_color
              left_text {
                richText
                text
              }
              link {
                id
                url
                link_type
                target
              }
              dropdown_content {
                richText
                text
              }
              link_text
            }
            primary {
              bottom_button_label
              bottom_button_link {
                id
                url
                link_type
                target
              }
              title {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyContactForm1 {
            id
            slice_label
            slice_type
            primary {
              bottom_message {
                richText
              }
              button_label
              email_label
              name_label
              notes_label
              phone_label
              form_title {
                text
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicPageDataBodyMap {
            id
            slice_label
            slice_type
          }
          ... on PrismicPageDataBodyLargeImageCard {
            id
            slice_type
            slice_label
            primary {
              title {
                richText
              }
              image {
                gatsbyImageData(srcSetMinWidth: 1500, placeholder: BLURRED)
                alt
              }
              description {
                richText
              }
              bottom_link_text
              bottom_link {
                id
                url
                link_type
                target
              }
            }
          }
        }
        body1 {
          ... on PrismicPageDataBody1Seo {
            id
            primary {
              meta_description
              meta_keywords
              meta_title
              social_media_image {
                url
              }
            }
            slice_type
          }
        }
      }
    }
  }
`

interface ILargeLinkSlice {
  primary: {
    label: string
    page_link: any
  }
}

const LargeLink = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ILargeLinkSlice
  const context = props.context as IPageTheme
  return (
    // <PrismicLink field={slice.primary.page_link}>
    <AppContainer withMobilePadding={true}>
      <PageLink link={slice.primary.page_link}>
        <StyledButton
          type="large"
          textColorHex={context.largeLinkColor || context.textColor}
        >
          {slice.primary.label}
        </StyledButton>
      </PageLink>
    </AppContainer>
    // </PrismicLink>
  )
}

const StyledButton = styled(Button)`
  text-align: center;
  margin-top: 160px;
`

export default withPrismicPreview(AbstractWebsitePage)
