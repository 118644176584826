import { SliceComponentProps } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { applyTextStyle } from '../../styles/typography'
import AppContainer from '../AppContainer'
import RichText from '../RichText'

interface ISlice {
  primary: {
    author: string
    quote_text: any
    author_avatar: any
  }
}

const Quote = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  return (
    <StyledAppContainer withMobilePadding={true}>
      <QuoteText>
        <RichText field={slice.primary.quote_text.richText}></RichText>
      </QuoteText>
      <AuthorContent>
        <GatsbyImage
          image={slice.primary.author_avatar.gatsbyImageData}
          alt={
            slice.primary.author_avatar ?? 'Person author of the quote above'
          }
          objectFit="cover"
          style={{
            width: '80px',
            height: '80px',
          }}
        ></GatsbyImage>
        <AuthorName>{slice.primary.author}</AuthorName>
      </AuthorContent>
    </StyledAppContainer>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin: 120px 0;
`

const QuoteText = styled.div`
  ${applyTextStyle('titleNormalRegular')};
  color: ${colors.bordeauxRed};
  max-width: 60vw;
`

const AuthorContent = styled.div`
  display: flex;
  align-items: center;
`
const AuthorName = styled.div`
  margin-left: 24px;
`

export default Quote
