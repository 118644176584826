/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'

interface IMeta {
  name: string
  content: string
}
interface IProps {
  noIndex?: boolean
  title?: string
  description?: string
  keywords?: string
  lang?: string
  meta?: IMeta[]
  image?: string
}

const Seo: React.FC<IProps> = ({
  noIndex,
  title,
  description,
  lang,
  meta,
  keywords,
  image,
}) => {
  const site = {
    siteMetadata: {
      title: 'worldofwork',
      description: 'worldofwork',
      author: 'worldofwork',
    },
  }

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = keywords || ''

  const socialImage =
    image ||
    'https://images.prismic.io/worldofwork/b11159f2-b8fe-4937-adad-24a0bbc5b8cf_home_background.jpg?auto=compress,format'
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={title && `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'keywords',
          content: metaKeywords,
        },
        {
          name: 'robots',
          content: noIndex ? 'noindex' : 'all',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: 'image',
          property: 'og:image',
          content: socialImage,
        },
        {
          property: 'og:image:secure_url',
          content: socialImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta || [])}
    />
  )
}

export default Seo
