import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { mqFrom, mqTo } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import AppContainer from '../AppContainer'
import { GridCol } from '../grid'
import GridRow from '../grid/GridRow'
import PageTitle from '../PageTitle'
import RichText from '../RichText'
import PageLink from '../PageLink'
import Button from '../Button'

interface IProps {
  className?: string
  image: any
  title: any
  description: string
  bottomLink?: any
  bottomLinkText?: string
}

const ExpertsCard: React.FC<IProps> = (props: IProps) => {
  //const [paragraph1, paragraph2] = props.description.split('\n')

  return (
    <AppContainer withMobilePadding={true}>
      <StyledGridRow className={props.className}>
        <GridCol l={8} m={4} s={4}>
          <TextContentBox>
            <div>
              <Title color={colors.grey} field={props.title}></Title>
              <Description color={colors.grey}>
                <RichText field={props.description} />
                {props.bottomLink.url !== null && (
                  <PageLink link={props.bottomLink}>
                    <Button type="medium">{props.bottomLinkText}</Button>
                  </PageLink>
                )}
              </Description>
            </div>
          </TextContentBox>
        </GridCol>
        <GridCol l={8} m={4} s={4}>
          <GatsbyImage
            image={props.image.gatsbyImageData}
            alt={props.image.alt ?? 'image'}
            objectFit="cover"
            style={{
              width: '100%',
              height: '100%',
              margin: '0',
            }}
          ></GatsbyImage>
        </GridCol>
      </StyledGridRow>
    </AppContainer>
  )
}

const StyledGridRow = styled(GridRow)`
  margin-top: 160px;
  ${mqTo.S`
    display: flex;
    flex-direction: row-reverse;
  `}
`

const Description = styled.div<{ color: string }>`
  ${applyTextStyle('subtle')}
  color: ${({ color }) => color};
`

const Title = styled(PageTitle)<{ color: string }>`
  ${applyTextStyle('titleNormal')};
  color: ${({ color }) => color};
  text-align: left;
  margin-bottom: 20px;
  ${mqFrom.S`
    margin-bottom: 30px;
  `}
`

const TextContentBox = styled.div`
  height: 100%;
  ${mqFrom.S`
    padding-right: 80px;
  `}
`

export default ExpertsCard
