import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { mqFrom } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import { GridCol } from '../grid'
import GridRow from '../grid/GridRow'
import PageTitle from '../PageTitle'
import RichText from '../RichText'
import PageLink from '../PageLink'
import Button from '../Button'

interface IProps {
  image: any
  backgroundColor: string
  title: string
  description: string
  left: boolean
  titleColor: string
  descriptionColor: string
  bottomLink?: any
  bottomLinkText?: string
}

//why two divs in TextContentBox
const RectangularCard: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledGridRow color={props.backgroundColor}>
      <GridCol l={8} m={8} s={4} order={props.left ? 1 : 3} mobileOrder={1}>
        <GatsbyImage
          image={props.image.gatsbyImageData}
          alt={props.image.alt ?? 'image'}
          objectFit="cover"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            margin: '0',
          }}
        ></GatsbyImage>
      </GridCol>
      <GridCol l={8} m={8} s={4} order={2}>
        <TextContentBox>
          <Title color={props.titleColor} field={props.title}></Title>
          <Description color={props.descriptionColor}>
            <RichText field={props.description}></RichText>
          </Description>
          {props.bottomLink?.url !== null && (
            <PageLink link={props.bottomLink}>
              <Button type="medium" textColorHex={props.descriptionColor}>
                {props.bottomLinkText}
              </Button>
            </PageLink>
          )}
        </TextContentBox>
      </GridCol>
    </StyledGridRow>
  )
}

const Description = styled.div<{ color: string }>`
  ${applyTextStyle('normal')}
  color: ${({ color }) => color};
`

const StyledGridRow = styled(GridRow)<{ color: string }>`
  background-color: ${({ color }) => color};
  height: auto;
`

const Title = styled(PageTitle)<{ color: string }>`
  color: ${({ color }) => color};
  ${applyTextStyle('titleNormal')};
  margin-bottom: 24px;
`

const TextContentBox = styled.div`
  padding: 24px 39px 31px;
  ${mqFrom.M`
    padding: 80px;
  `}
`

export default RectangularCard
