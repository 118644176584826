import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import colors from '../styles/colors'
import scroll from '../utils/scroll.helper'
import { applyTextStyle } from '../styles/typography'
import { globalHistory } from '@reach/router'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { Breakpoint, mqFrom } from '../styles/responsive'

import useScrollDirection from '../utils/useScrollDirection'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

import { IPageTheme } from '../pages/{PrismicPage.url}'
import { linkResolver } from '../../linkResolver'
import PageLink from './PageLink'

interface ILink {
  label: string
  submenu_of: string | null
  link?: any
}

interface IProps {
  theme: IPageTheme
  lang: string
  altLang?: any
  isHomePage: boolean
  currentPageSlug: string
}

const Navigation: React.FC<IProps> = ({
  lang,
  altLang,
  theme,
  isHomePage,
  currentPageSlug,
}) => {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicWebsiteConfig {
        edges {
          node {
            _previewable
            lang
            alternate_languages {
              id
              type
              lang
              uid
            }
            data {
              navigation_links {
                link {
                  id
                  lang
                  url
                  link_type
                  uid
                }
                label
                submenu_of
              }
            }
          }
        }
      }
    }
  `)

  const merge = useMergePrismicPreviewData(staticData)
  const data = merge.data.allPrismicWebsiteConfig.edges.find(
    (e: any) => e.node.lang === lang
  )
  const links: ILink[] = data.node.data.navigation_links

  const scrollDirection = useScrollDirection()

  const handleViewportWidth = () => {
    if (window !== undefined) {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }
  }

  useEffect(() => {
    handleViewportWidth()
    window.addEventListener('resize', handleViewportWidth)

    return () => {
      if (window !== undefined) {
        window.removeEventListener('resize', handleViewportWidth)
      }
    }
  }, [])

  const [currentRoute, setCurrentRoute] = useState<string>(
    globalHistory.location.pathname
  )

  useEffect(() => {
    return globalHistory.listen((e) => {
      setCurrentRoute(e.location.pathname)
    })
  }, [currentRoute])

  const handleShowNav = () => {
    if (scrollDirection === 'down') {
      return false
    } else {
      return true
    }
  }

  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false)
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  })

  useEffect(() => {
    if (dimensions?.width > Breakpoint.S) {
      setMobileNavbarOpen(false)
      scroll.disable()
    }
  }, [dimensions.width])

  const isBgTransparent =
    (isHomePage && typeof window === `undefined`) ||
    (isHomePage &&
      typeof window !== `undefined` &&
      window.scrollY < window.innerHeight &&
      !mobileNavbarOpen)

  return (
    <Navbar show={handleShowNav()} transparentBg={isBgTransparent}>
      {!isBgTransparent && (
        <PageLink
          link={{
            url: lang === 'en-gb' ? '/' : `/${lang}`,
            link_type: 'Document',
            id: '',
          }}
        >
          <Logo src={theme.logo}></Logo>
        </PageLink>
      )}
      <MobileBurger
        onClick={() => {
          setMobileNavbarOpen(!mobileNavbarOpen)
          if (mobileNavbarOpen) scroll.disable()
          else scroll.enable()
        }}
        isMobileNavigation={mobileNavbarOpen}
      >
        <BurgerBarHalfTop
          className="burgerLine"
          isMobileNavigation={mobileNavbarOpen}
        />
        <BurgerBarMiddle
          className="burgerLine"
          isMobileNavigation={mobileNavbarOpen}
        />
        <BurgerBarHalfBottom
          className="burgerLine"
          isMobileNavigation={mobileNavbarOpen}
        />
      </MobileBurger>
      <ItemsList isMobileNavigation={mobileNavbarOpen}>
        {links
          .filter((l) => !l.submenu_of)
          .map((link) => {
            const childLinks = links.filter((l) => link.label == l.submenu_of)

            return (
              <Item
                hasChildren={!!childLinks.length}
                onClick={() => {
                  if (mobileNavbarOpen) {
                    setMobileNavbarOpen(!mobileNavbarOpen)
                    scroll.disable()
                  }
                }}
                key={'nav-link-' + link.label}
                className={
                  (isHomePage &&
                    (link.link?.url == '/' || link.link?.url == '/nl-nl/')) ||
                  (link.link?.url || '').includes(currentPageSlug) ||
                  childLinks.some((link) =>
                    (link.link?.url || '').includes(currentPageSlug)
                  )
                    ? 'currentPage'
                    : undefined
                }
              >
                {!link.submenu_of && (
                  <span>
                    <PageLink link={link.link}>{link.label}</PageLink>
                  </span>
                )}
                <SubItems>
                  <SubItem>
                    {links
                      .filter((l) => link.label == l.submenu_of)
                      .map((subs) => {
                        const hasSecondSubMenu =
                          links.filter((k) => subs.label === k.submenu_of)
                            .length > 0

                        return (
                          <PageLink
                            key={'sub-link-' + subs.label}
                            link={subs.link}
                          >
                            <Hover hasSecondSubMenu={hasSecondSubMenu}>
                              {subs.label}
                              <SecondSubMenu>
                                {links
                                  .filter((k) => subs.label === k.submenu_of)
                                  .map((subMenu) => (
                                    <SecondSubItem
                                      key={'sub-sub-link-' + subs.label}
                                    >
                                      <PageLink link={subMenu.link}>
                                        {subMenu.label}
                                      </PageLink>
                                    </SecondSubItem>
                                  ))}
                              </SecondSubMenu>
                            </Hover>
                          </PageLink>
                        )
                      })}
                  </SubItem>
                </SubItems>
              </Item>
            )
          })}
        <Item hasChildren={false}>
          <LanguageSelector
            className={'nav-language-selector'}
            isMobileNavigation={mobileNavbarOpen}
          >
            <LanguageOption
              className={lang == 'en-gb' ? 'selected-language' : ''}
              isMobileNavigation={mobileNavbarOpen}
              onClick={() => {
                if (lang == 'en-gb') return
                if (altLang) navigate(linkResolver(altLang.document))
                else navigate('/')
              }}
            >
              EN
            </LanguageOption>
            <LanguageOption
              className={lang == 'nl-nl' ? 'selected-language' : ''}
              isMobileNavigation={mobileNavbarOpen}
              onClick={() => {
                if (mobileNavbarOpen) scroll.disable()
                if (lang == 'nl-nl') return
                if (altLang) navigate(linkResolver(altLang.document))
                else navigate('/nl-nl')
              }}
            >
              NL
            </LanguageOption>
          </LanguageSelector>
        </Item>
      </ItemsList>
    </Navbar>
  )
}

const Navbar = styled.nav<{
  show: boolean
  transparentBg: boolean
}>`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 500ms ease-in-out, background-color 300ms ease-in-out;
  background-color: ${({ transparentBg }) =>
    transparentBg ? 'transparent' : 'var(--page-background-color)'};
  ${mqFrom.S2`
    ul > li a {
      ${({ transparentBg }: any) =>
        transparentBg &&
        `
        color: ${colors.white} !important;
        `}
    }
  `}

  ${mqFrom.S2`
    ul > li {
      ${({ transparentBg }: any) =>
        transparentBg &&
        `
      color: ${colors.white} !important;
      `}
    }
  `}

  ${({ transparentBg }) =>
    transparentBg
      ? `

    .burgerLine {
      background: ${colors.white} !important;
    }
    .nav-language-selector {
      color: ${colors.white};
    }
    .selected-language {
      color: ${colors.grey};
    }
    & .currentPage span {
      border-color: ${colors.white} !important;
    }
    `
      : ``};

  ${({ show }: { show: boolean }) =>
    !show &&
    `
    transform: translateY(-100%);
  `};
`

const Logo = styled.img`
  margin-left: 31px;
  height: 100%;
  max-width: 45vw;

  ${mqFrom.S2`
    margin-right: auto;
    margin-left: 31px;
  `}
`

const MobileBurger = styled.div<{ isMobileNavigation: boolean }>`
  margin-left: auto;
  width: 47px;
  height: 35px;
  display: flex;
  float: right;
  margin-right: 15px;
  flex-direction: column;
  justify-content: space-between;
  z-index: 30;
  transition: transform 605ms ease-out, opacity 0.6s linear;
  ${({ isMobileNavigation }) =>
    isMobileNavigation &&
    `
    width: 55px;
    transform: rotate(-45deg);
  `};
  ${mqFrom.S2`
    display: none;
  `}
`

const BurgerBarMiddle = styled.div<{ isMobileNavigation: boolean }>`
  width: 100%;
  height: 4px;
  border-radius: 0.8rem;
  background: var(--site-text-color);
`

const BurgerBarHalfTop = styled.div<{ isMobileNavigation: boolean }>`
  width: 50%;
  height: 4px;
  border-radius: 0.8rem;
  background: var(--site-text-color);
  transform-origin: right;
  transition: transform 0.65s cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform: ${({ isMobileNavigation }) =>
    isMobileNavigation && 'rotate(-450deg) translateX(.8rem) !important;'};
`

const BurgerBarHalfBottom = styled.div<{ isMobileNavigation: boolean }>`
  width: 50%;
  align-self: flex-end;
  height: 4px;
  border-radius: 0.8rem;
  background: var(--site-text-color);
  transform-origin: left;
  transition: transform 0.65s cubic-bezier(0.54, -0.81, 0.57, 0.57);
  transform: ${({ isMobileNavigation }) =>
    isMobileNavigation && 'rotate(-450deg) translateX(-.8rem) !important'};
`

const ItemsList = styled.ul<{
  isMobileNavigation: boolean
}>`
  transition: max-height 0.3s ease-out;
  height: 100vh;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  background-color: ${colors.yellow};
  overflow: hidden;
  max-height: ${({ isMobileNavigation }) =>
    isMobileNavigation ? '100vh' : '0'};

  ${mqFrom.S2`
    position: relative;
    max-height: initial;
    height: auto;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    overflow: visible;
  `}
`

const SecondSubMenu = styled.div`
  z-index: 0;
  ${mqFrom.S2`
    margin-left: 28px;
  `}
`

const SecondSubItem = styled.div`
  cursor: pointer;
  a {
    display: block;
    padding: 6px 0px 0px 0px !important;
    font-size: 16px;
  }

  ${mqFrom.S2`
    a {
      padding: 6px 0px 0px 0px !important;
      font-size: 16px;
    }
  `}
`

const Hover = styled.div<{ hasSecondSubMenu: boolean }>`
  &:hover {
    ${({ hasSecondSubMenu }) =>
      hasSecondSubMenu &&
      css`
        ${SecondSubMenu} {
          display: block;
        }
      `}
  }
`

const SubItems = styled.div`
  z-index: -1;
  ${mqFrom.S2`
    display: none;
    position: absolute;
    left: 0;
    max-width: 289.63px;
  `}
`

const SubItem = styled.div`
  ${applyTextStyle('subtle')};
  cursor: pointer;
  a {
    display: block;
    padding-top: 10px;
  }

  a:first-child {
    padding-top: 20px;
  }

  ${mqFrom.S2`
    a {
      display: block;
      background-color: var(--highlights-color);
      color: var(--highlights-text-color);
      padding: 0 100px 10px 25px;
      white-space: nowrap;
      &:first-child {
        padding-top: 25px;
      }
      &:last-child {
        padding-bottom: 25px;
      }
    }
  `}
`

const Item = styled.li<{ hasChildren: boolean }>`
  ${applyTextStyle('normal')};
  cursor: pointer;
  list-style: none;
  font-size: 32px;
  padding-bottom: 40px;
  position: relative;
  span {
    padding: 0 2px 4px;
    border-bottom: 2px solid transparent;
  }
  &.currentPage span {
    border-color: var(--site-text-color);
  }

  &:first-child {
    padding-top: 100px;
  }

  ${mqFrom.S2`
    font-size: 12px;
    padding: 10px 3px 5px !important;
    list-style: none;
  `}

  ${mqFrom.S3`
    font-size: 16px;
    padding: 10px 6px 5px !important;
  `}

  ${mqFrom.M`
    font-size: 18px;
    padding: 10px 9px 5px !important;
  `}

  ${mqFrom.L`
    font-size: 22px;
    padding: 10px 12px 5px !important;
  `}

  ${mqFrom.XL`
    ${applyTextStyle('normal')};
    padding: 10px 26px 5px !important;
  `}

  &: hover {
    ${mqFrom.S2`
      background-color: ${({ hasChildren }) =>
        hasChildren && `var(--highlights-color)`};
    `}
    ${mqFrom.S2`
      &,
      & > span a {
        color: ${({ hasChildren }) =>
          hasChildren && `var(--highlights-text-color)`};
      }
    `}
    ${mqFrom.S2`
      > div {
        display: ${({ hasChildren }) => hasChildren && 'block'};
      }
    `}
    ${mqFrom.S2`
      &.currentPage span {
        border-color: ${({ hasChildren }) =>
          hasChildren && `var(--highlights-text-color)`};
      }
    `}
  }
`

const LanguageSelector = styled.div<{ isMobileNavigation: boolean }>`
  display: flex;
  ${({ isMobileNavigation }: { isMobileNavigation: boolean }) =>
    isMobileNavigation
      ? `
        padding-left: 0 !important;
        place-content: center;
      `
      : `
  `};
`

const LanguageOption = styled.div<{
  isMobileNavigation: boolean
}>`
  ${applyTextStyle('normal', 'desktop')};
  margin: 0 12px;
  padding: ${({ isMobileNavigation }) =>
    isMobileNavigation ? '17px 10px' : '8px'};
  cursor: pointer;
  &.selected-language {
    background: ${({ isMobileNavigation }) =>
      isMobileNavigation && colors.brown};
    color: ${({ isMobileNavigation }) => isMobileNavigation && colors.white};
  }

  ${mqFrom.S2`
    font-size: 12px;
  `}

  ${mqFrom.S3`
    font-size: 16px;
  `}

  ${mqFrom.M`
    font-size: 18px;
  `}

  ${mqFrom.L`
    font-size: 22px;
  `}

  ${mqFrom.XL`
    ${applyTextStyle('normal')};
  `}
`

export default Navigation
