import React from 'react'
import styled, { css } from 'styled-components'
import {
  IGridBreakpoint,
  IGridColProps,
  ColSpanAmount,
} from '../../common/models/grid'
import { gridConfig, mqFrom } from '../../styles/responsive'

interface IProps extends IGridColProps {
  // Default tag name is 'div'
  tagName?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  className?: string
  children?: React.ReactNode
}

const GridCol: React.FC<IProps> = ({
  tagName,
  s,
  m,
  l,
  children,
  className,
  order,
  mobileOrder,
}) => {
  !tagName && (tagName = 'div')
  return (
    <Col
      {...{ s, m, l }}
      as={tagName}
      className={className}
      order={order}
      mobileOrder={mobileOrder}
    >
      {children}
    </Col>
  )
}

const getColStyles = (bp: IGridBreakpoint, colSpan: ColSpanAmount) => {
  const size = bp.startsFrom

  return css`
    @media only screen and (min-width: ${size}px) {
      flex-basis: ${(100 / bp.maxColumns) * colSpan}%;
      max-width: ${(100 / bp.maxColumns) * colSpan}%;
    }
  `
}

const findBreakpoint = (bpName: string) =>
  gridConfig.breakpoints.find((bp) => bp.name === bpName)

export const Col = styled.div<IGridColProps>`
  flex-grow: 1;
  flex-shrink: 0;
  ${(props) => props.s && getColStyles(findBreakpoint('s')!, props.s)}
  ${(props) => props.m && getColStyles(findBreakpoint('m')!, props.m)}
  ${(props) => props.l && getColStyles(findBreakpoint('l')!, props.l)}
  ${({ mobileOrder, order }) =>
    (mobileOrder || order) &&
    css`
      order: ${mobileOrder || order};
    `}
  ${({ order }) =>
    order &&
    css`
      ${mqFrom.S`
        order: ${order};
      `}
    `}
`

export default GridCol
