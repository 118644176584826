import { useState, useEffect } from 'react'

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(
    null
  )
  const [prevOffset, setPrevOffset] = useState<number>(0)

  const toggleScrollDirection = () => {
    const scrollY = window.scrollY

    if (scrollY === 0) {
      setScrollDirection(null)
    }
    if (scrollY > 24) {
      if (scrollY > prevOffset) {
        setScrollDirection('down')
      } else if (scrollY < prevOffset) {
        setScrollDirection('up')
      }
      setPrevOffset(scrollY)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleScrollDirection)
    return () => {
      window.removeEventListener('scroll', toggleScrollDirection)
    }
  })

  return scrollDirection
}

export default useScrollDirection
