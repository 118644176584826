import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import { IPrismicLink } from '../PageLink'
import ArrowButtonCard from '../cards/ArrowButtonCard'
import styled from 'styled-components'

interface ISlice {
  primary: {
    title: {
      richText: string
    }
    description: {
      richText: string
    }
    bottom_link_text: string
    bottom_link: IPrismicLink
    image: any
  }
}

const ArrowButtonCardSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  return (
    <Wrapper>
      <ArrowButtonCard
        image={slice.primary.image}
        description={slice.primary.description.richText}
        arrowText={slice.primary.bottom_link_text}
        arrowLink={slice.primary.bottom_link}
        title={slice.primary.title.richText}
      ></ArrowButtonCard>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

export default ArrowButtonCardSlice
