import React from 'react'
import { GridCol, GridRow } from './grid'
import { ICard } from './ArrowSlider'
import PageLink from './PageLink'
import Slides from './Animations'
import WorkAtWoWCard, { IWorkAtWowCard } from './cards/WorkAtWoWCard'
import styled from 'styled-components'
import PostImageCard from './cards/PostImageCard'
import { mqFrom } from '../styles/responsive'

interface IProps {
  cards: ICard[]
  className?: string
  workAtWoWCardInfo: IWorkAtWowCard
  lang?: string
}

const Rectangles: React.FC<IProps> = ({
  className,
  cards,
  workAtWoWCardInfo,
  lang,
}) => {
  return (
    <GridRow className={className}>
      <GridCol l={1} m={0} s={0}></GridCol>
      <GridCol l={14} m={8} s={4}>
        <Slides>
          <GridRow>
            {cards.map((card, i) => (
              <>
                <StyledGridCol
                  l={7}
                  m={3}
                  s={4}
                  key={'blog-card-' + card.title}
                >
                  <Slides>
                    <PageLink link={card.link}>
                      <PostImageCard
                        key={'rectangle-card' + card.title}
                        image={card.image}
                        title={card.title}
                        description={card.text}
                        alt={card.alt}
                        firstPublicationDate={card.firstPublicationDate}
                        lang={lang}
                      ></PostImageCard>
                    </PageLink>
                  </Slides>
                </StyledGridCol>
                {i % 2 == 0 && <GridCol l={2} m={1} s={0}></GridCol>}
              </>
            ))}
            <StyledGridCol l={7} m={3} s={4}>
              <WorkAtWoWCard
                wowCard={{
                  imageSrc: workAtWoWCardInfo.imageSrc,
                  title: workAtWoWCardInfo.title,
                  description: workAtWoWCardInfo.description,
                  arrowText: workAtWoWCardInfo.arrowText,
                  backgroundColor: workAtWoWCardInfo.backgroundColor,
                  elementsColor: workAtWoWCardInfo.elementsColor,
                  pageLink: workAtWoWCardInfo.pageLink,
                }}
              ></WorkAtWoWCard>
            </StyledGridCol>
          </GridRow>
        </Slides>
      </GridCol>
      <GridCol l={1} m={0} s={0}></GridCol>
    </GridRow>
  )
}

const StyledGridCol = styled(GridCol)`
  padding-bottom: 80px;
  ${mqFrom.S`
    &:nth-child(3) {
      margin-top: 120px;
    }
    &:nth-child(3n + 4) {
      margin-top: -120px;
    }
  `}
`
export default Rectangles
