import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLinkedIn = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 38.987 38.987"
  >
    <path
      id="Icon_awesome-linkedin"
      data-name="Icon awesome-linkedin"
      d="M36.2,2.25H2.776A2.8,2.8,0,0,0,0,5.061V38.426a2.8,2.8,0,0,0,2.776,2.811H36.2a2.8,2.8,0,0,0,2.785-2.811V5.061A2.8,2.8,0,0,0,36.2,2.25ZM11.783,35.667H6V17.062h5.787V35.667ZM8.894,14.52a3.35,3.35,0,1,1,3.35-3.35,3.352,3.352,0,0,1-3.35,3.35Zm24.55,21.147H27.665V26.617c0-2.158-.044-4.934-3-4.934-3.011,0-3.472,2.35-3.472,4.778v9.207H15.412V17.062h5.543V19.6h.078a6.086,6.086,0,0,1,5.474-3c5.848,0,6.936,3.855,6.936,8.868Z"
      transform="translate(0 -2.25)"
      fill={props.color}
    />
  </svg>
)

export default IconLinkedIn
