import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { mqFrom } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import AppContainer from '../AppContainer'
import Button from '../Button'
import { GridCol } from '../grid'
import GridRow from '../grid/GridRow'
import PageLink from '../PageLink'
import PageTitle from '../PageTitle'
import RichText from '../RichText'
import * as prismicH from '@prismicio/helpers'

interface IProps {
  title: any
  description: string
  sectionTitles: any[]
  sectionDescriptions: any[]
  titleArrowColor: string
  contentColor: string
  left: boolean
  image: any
  pageLinks: any[]
}

const SectionsCard: React.FC<IProps> = (props: IProps) => {
  return (
    <AppContainer>
      <GridRow>
        <StyledGridCol
          order={props.left ? 1 : 3}
          l={8}
          m={3}
          s={4}
          mobileOrder={1}
        >
          <GatsbyImage
            image={props.image.gatsbyImageData}
            alt={props.image.alt ?? 'image'}
            objectFit="cover"
            style={{
              width: '100%',
              height: '100vh',
              margin: '0',
              position: 'sticky',
              top: 0,
            }}
          ></GatsbyImage>
        </StyledGridCol>
        <GridCol order={2} l={8} m={5} s={4}>
          <TextContentBox left={props.left}>
            <Title color={props.titleArrowColor} field={props.title}></Title>
            <Description color={props.contentColor}>
              <RichText field={props.description}></RichText>
            </Description>
            {props.sectionTitles.map((title, index) => (
              <StyledGridRow borderColor={props.contentColor} key={index}>
                <GridCol l={7} m={4} s={4}>
                  <SectionTitle
                    color={props.contentColor}
                    field={title}
                  ></SectionTitle>
                </GridCol>
                <GridCol l={9} m={4} s={4}>
                  <SectionDescription color={props.contentColor}>
                    {prismicH.asText(props.sectionDescriptions[index])}
                  </SectionDescription>
                  {props.pageLinks[index]?.url && (
                    <PageLink link={props.pageLinks[index]}>
                      <Button type="small" textColorHex={props.titleArrowColor}>
                        {'Read more'}
                      </Button>
                    </PageLink>
                  )}
                </GridCol>
              </StyledGridRow>
            ))}
          </TextContentBox>
        </GridCol>
      </GridRow>
    </AppContainer>
  )
}

const StyledGridRow = styled(GridRow)<{ borderColor: string }>`
  border-top: 2px solid ${({ borderColor }) => borderColor};
  padding-top: 8px;
  padding-bottom: 40px;
`

const SectionDescription = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  text-align: left;
  ${applyTextStyle('subtle')};
`

const SectionTitle = styled(PageTitle)<{ color: string }>`
  color: ${({ color }) => color};
  ${applyTextStyle('large')};
  text-align: left;
`

const Description = styled.div<{ color: string }>`
  ${applyTextStyle('normal')}
  color: ${({ color }) => color};
  margin-bottom: 80px;
`

const Title = styled(PageTitle)<{ color: string }>`
  color: ${({ color }) => color};
  ${applyTextStyle('titleNormal')};
  text-align: left;
  margin-bottom: 40px;
  margin-top: 25px;
  ${mqFrom.S`
    margin-top: 0;
  `}
`

const TextContentBox = styled.div<{ left: boolean }>`
  padding: 0 6.25vw;
  ${mqFrom.S`
    padding: ${({ left }: any) =>
      `56px ${left ? '0' : '55px'} 10px ${!left ? '0' : '55px'}`};
    height: 100%;
  `}
`

const StyledGridCol = styled(GridCol)``

export default SectionsCard
