import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import TitleContent from '../TitleContent'
import Dropdowns from '../Dropdowns'
import { IPrismicLink } from '../PageLink'

interface ISlice {
  items: {
    background_color1: string
    content_color: string
    center_text: {
      richText: string
    }
    left_text: {
      richText: string
    }
    dropdown_content: {
      richText: string
    }
    link: any
    link_text: string
  }[]
  primary: {
    bottom_button_label: string
    bottom_button_link: IPrismicLink
    title: {
      richText: string
    }
  }
}

const DropdownArrowListSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const leftTexts = slice.items.map((item) => item.left_text.richText)
  const centerTexts = slice.items.map((item) => item.center_text.richText)
  const backgroundColors = slice.items.map((item) => item.background_color1)
  const links = slice.items.map((item) => item.link)
  const contentColors = slice.items.map((item) => item.content_color)
  const dropdownContents = slice.items.map(
    (item) => item.dropdown_content.richText
  )
  const linkTexts = slice.items.map((item) => item.link_text)

  return (
    <TitleContent title={slice.primary.title.richText}>
      <Dropdowns
        leftTexts={leftTexts}
        centerTexts={centerTexts}
        backgroundColors={backgroundColors}
        contentColors={contentColors}
        links={links}
        arrowButtonText={slice.primary.bottom_button_label}
        arrowButtonLink={slice.primary.bottom_button_link}
        dropdownContents={dropdownContents}
        linkTexts={linkTexts}
      ></Dropdowns>
    </TitleContent>
  )
}

export default DropdownArrowListSlice
