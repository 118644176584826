import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { mqFrom } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import { GridCol } from '../grid'
import GridRow from '../grid/GridRow'
import PageTitle from '../PageTitle'
import RichText from '../RichText'

export interface IProps {
  image: any
  title: any
  titleColor: string
  backgroundColor: string
  left?: boolean
  description: string
  descriptionColor: string
  alt: string
}

const NormalCard: React.FC<IProps> = (props: IProps) => {
  return (
    <Wrapper>
      <StyledGridRow color={props.backgroundColor}>
        <StyledGridCol l={6} m={2} s={1} left={!!props.left}>
          <StyledGatsbyImage
            image={props.image}
            alt={props.alt ?? 'image'}
            objectFit="cover"
            style={{
              width: '100%',
              margin: '0',
            }}
          ></StyledGatsbyImage>
        </StyledGridCol>
        <ContentCol l={10} m={6} s={3}>
          <TextContentBox>
            <div>
              <div style={{ height: '15px' }}></div>
              <StyledTitle
                color={props.titleColor}
                field={props.title}
              ></StyledTitle>
              <Description color={props.descriptionColor}>
                <RichText field={props.description}></RichText>
              </Description>
            </div>
          </TextContentBox>
        </ContentCol>
      </StyledGridRow>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  & img {
    transition: all 0.2s ease-in-out !important;
  }
  &:hover {
    & img {
      transform: scale(1.1);
    }
  }
`

const StyledGridCol = styled(GridCol)<{ left: boolean }>`
  order: ${({ left }) => (left ? 1 : 3)};
`

const ContentCol = styled(GridCol)`
  order: 2;
  padding-bottom: 20px;
`

const Description = styled.div<{ color: string }>`
  ${applyTextStyle('normal')}
  color: ${({ color }) => color};
`

const StyledGridRow = styled(GridRow)<{ color: string }>`
  background-color: ${({ color }) => color};
  height: 100%;
`

const Title = styled(PageTitle)<{ color: string }>`
  color: ${({ color }) => color};
  ${applyTextStyle('titleSubtle')};
  text-align: left;
`

const StyledTitle = styled(Title)`
  margin-bottom: 24px;
`

const TextContentBox = styled.div`
  padding: 10px 10px 10px 15px;
  height: 100%;
  display: flex;
  align-items: center;
  ${mqFrom.S`
    padding: 40px 20px 20px 40px;
  `}
`

const StyledGatsbyImage = styled(GatsbyImage)`
  ${mqFrom.S`
    height: 100%;
  `}
`

export default NormalCard
