import React from 'react'
import AppContainer from './AppContainer'
import styled from 'styled-components'
import PageLink from './PageLink'

interface IProps {
  breadcrumbLevelOneLabel: string
  breadcrumbLevelOneLink: any
  breadcrumbLevelTwoLabel: string
  breadcrumbLevelTwoLink: any
  breadcrumbLevelThreeLabel: string
  breadcrumbLevelThreeLink: any
  breadcrumbLevelFourLabel: string
  theme: any
}

const Breadcrumb: React.FC<IProps> = ({
  breadcrumbLevelOneLabel,
  breadcrumbLevelOneLink,
  breadcrumbLevelTwoLabel,
  breadcrumbLevelTwoLink,
  breadcrumbLevelThreeLabel,
  breadcrumbLevelThreeLink,
  breadcrumbLevelFourLabel,
  theme,
}) => {
  const textColor = theme.textColor as string

  return (
    <StyledAppContainer
      withMobilePadding={true}
      animated={false}
      largeContainer={true}
    >
      {breadcrumbLevelOneLink || breadcrumbLevelOneLabel ? (
        <PageLink link={breadcrumbLevelOneLink}>
          <HouseIcon textColor={textColor}></HouseIcon>
        </PageLink>
      ) : (
        <></>
      )}
      {breadcrumbLevelTwoLabel ? (
        <span>
          <Separator>
            <Chevron textColor={textColor}></Chevron>
          </Separator>
          {breadcrumbLevelThreeLabel ? (
            <>
              <OnHover color={textColor}>
                <PageLink link={breadcrumbLevelTwoLink}>
                  {breadcrumbLevelTwoLabel}
                </PageLink>
              </OnHover>
            </>
          ) : (
            <>{breadcrumbLevelTwoLabel}</>
          )}
        </span>
      ) : (
        <></>
      )}
      {breadcrumbLevelThreeLabel ? (
        <span>
          <Separator>
            <Chevron textColor={textColor}></Chevron>
          </Separator>
          {breadcrumbLevelFourLabel ? (
            <>
              <OnHover color={textColor}>
                <PageLink link={breadcrumbLevelThreeLink}>
                  {breadcrumbLevelThreeLabel}
                </PageLink>
              </OnHover>
            </>
          ) : (
            <>{breadcrumbLevelThreeLabel}</>
          )}
        </span>
      ) : (
        <></>
      )}
      {breadcrumbLevelFourLabel ? (
        <span>
          <Separator>
            <Chevron textColor={textColor}></Chevron>
          </Separator>
          <>{breadcrumbLevelFourLabel}</>
        </span>
      ) : (
        <></>
      )}
    </StyledAppContainer>
  )
}

const StyledAppContainer = styled(AppContainer)`
  margin-bottom: 20px;
`

const Separator = styled.span`
  margin: 16px 16px;
`

const OnHover = styled.div<{ color?: string }>`
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50% !important;
    width: 0;
    height: 2px;
    background-color: ${({ color }) => `${color}`};
    transition: width 0.3s ease, left 0.3s ease;
    transform: translateX(-50%);
  }

  &:hover:before {
    width: 100%;
    left: 0;
  }
`

const Chevron = ({ textColor }) => (
  // Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="16"
    width="10"
    viewBox="0 0 320 512"
  >
    <path
      d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192"
      fill={textColor}
    />
  </svg>
)

const HouseIcon = ({ textColor }) => (
  // Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    width="22"
    viewBox="0 0 576 512"
  >
    <path
      d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"
      fill={textColor}
    />
  </svg>
)

export default Breadcrumb
