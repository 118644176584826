exports.linkResolver = (doc) => {
  const mainLang = 'en-gb'
  const prefix = doc.lang === mainLang ? '' : `/${doc.lang}`
  // URL for a page type
  if (doc.type === 'page') {
    if (doc.uid === 'index') {
      return doc.lang === mainLang ? '/' : `/${doc.lang}`
    }
    if (doc.tags?.includes('our world')) {
      if (doc.tags?.includes('strategy and design')) {
        return `${prefix}/our-world/strategy-and-design/${doc.uid}`
      }
      if (doc.tags?.includes('implementation')) {
        return `${prefix}/our-world/implementation/${doc.uid}`
      }
      return `${prefix}/our-world/${doc.uid}`
    }
    if (doc.tags?.includes('partnerships')) {
      return `${prefix}/partnerships/${doc.uid}`
    }
    if (doc.tags?.includes('blog')) {
      return `${prefix}/expertise/${doc.uid}`
    }
    if (doc.tags?.includes('partners')) {
      return `${prefix}/partners/${doc.uid}`
    }
    if (doc.tags?.includes('services')) {
      return `${prefix}/services/${doc.uid}`
    }
    if (doc.tags?.includes('case')) {
      return `${prefix}/cases/${doc.uid}`
    }
    if (doc.tags?.includes('freelance')) {
      return `${prefix}/freelance/${doc.uid}`
    }
    if (doc.tags?.includes('partner')) {
      return `${prefix}/partner/${doc.uid}`
    }
    return `${prefix}/${doc.uid}`
  }

  // Backup for all other types
  return `/`
}
