import React from 'react'
import NormalCard, { IProps as NormalCardInfo } from './cards/NormalCard'
import styled from 'styled-components'
import { mqFrom } from '../styles/responsive'
import Slides from './Animations'
import { GridCol, GridRow } from './grid'

interface IProps {
  className?: string
  cardsInfo: NormalCardInfo[]
}

const renderNormalCard = (cardInfo: NormalCardInfo) => (
  <NormalCard
    image={cardInfo.image}
    title={cardInfo.title}
    titleColor={cardInfo.titleColor}
    backgroundColor={cardInfo.backgroundColor}
    left={cardInfo.left}
    description={cardInfo.description}
    descriptionColor={cardInfo.descriptionColor}
    alt={cardInfo.alt}
  ></NormalCard>
)

const NormalNGrid: React.FC<IProps> = ({ className, cardsInfo }) => {
  return (
    <Wrapper className={className}>
      <MobileRow>
        {cardsInfo.map((cardInfo, i) => (
          <StyledGridCol l={8} m={4} s={4} key={'card-info-' + cardInfo.title}>
            <StyledSlides>
              {renderNormalCard({
                ...cardInfo,
                left: Math.floor(i / 2) % 2 === 0,
              })}
            </StyledSlides>
          </StyledGridCol>
        ))}
      </MobileRow>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const MobileRow = styled(GridRow)`
  ${mqFrom.S`

  `}
`

const StyledGridCol = styled(GridCol)`
  &:nth-child(2n) {
    padding-right: calc(100vw / 8);
  }
  &:nth-child(2n + 1) {
    padding-left: calc(100vw / 8);
  }
  ${mqFrom.S`
    padding-left: 0 !important;
    padding-right: 0 !important;
    &:nth-child(1) {
      padding-bottom: 120px;
    }
    &:nth-child(2) {
      padding-top: 120px;
    }
    &:nth-child(2n + 3) {
      margin-top: -120px;
      padding-bottom: 120px;
    }
  `}
  > div {
    height: 100% !important;
  }
`

const StyledSlides = styled(Slides)`
  height: 100%;
`
export default NormalNGrid
