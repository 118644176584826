import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { Color } from '../../common/models/colors'
import colors from '../../styles/colors'
import { mqFrom } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import AppContainer from '../AppContainer'
import Button from '../Button'
import { GridCol } from '../grid'
import GridRow from '../grid/GridRow'
import PageTitle from '../PageTitle'
import PageLink from '../PageLink'

interface IProps {
  image: any
  title: any
  points: string[]
  arrowColor: Color
  arrowText?: string
  arrowLink?: any
}

const PointsCard: React.FC<IProps> = (props: IProps) => {
  return (
    <AppContainer withMobilePadding={true}>
      <StyledGridRow>
        <ContentGridCol l={8} m={4} s={4}>
          <ContentPart>
            <Title field={props.title}></Title>
            <div>
              {props.points.map((point: string) => (
                <BorderedText key={point}>{point}</BorderedText>
              ))}
            </div>
            {props.arrowText && props.arrowLink && (
              <PageLink link={props.arrowLink}>
                <Button type="medium" textColor={props.arrowColor}>
                  {props.arrowText}
                </Button>
              </PageLink>
            )}
          </ContentPart>
        </ContentGridCol>
        <ImageGridCol l={8} m={4} s={4}>
          <GatsbyImage
            image={props.image.gatsbyImageData}
            alt={props.image.alt ?? 'Image'}
            objectFit="cover"
            style={{
              width: '100%',
              height: '100%',
            }}
          ></GatsbyImage>
        </ImageGridCol>
      </StyledGridRow>
    </AppContainer>
  )
}

const StyledGridRow = styled(GridRow)`
  margin-top: 80px;
  ${mqFrom.S`
    margin-top: 160px;
  `}
`

const ContentPart = styled.div`
  padding-top: 20px;
  ${mqFrom.S`
    padding-right: 80px;
    margin: 140px 0px 80px;
  `}
`

const BorderedText = styled.div`
  border-top: 2px solid ${colors.grey};
  margin-bottom: 40px;
  ${applyTextStyle('large', 'desktop')};
  padding-top: 8px;
`

const Title = styled(PageTitle)`
  ${applyTextStyle('titleNormal')};
  color: ${colors.grey};
  text-align: left;
  margin-bottom: 40px;
`

const ImageGridCol = styled(GridCol)`
  order: 1;
  ${mqFrom.S`
    order: 3;
  `}
`

const ContentGridCol = styled(GridCol)`
  order: 2;
`

export default PointsCard
