import { Form, Formik, FormikHelpers } from 'formik'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import { mqTo } from '../styles/responsive'
import { applyTextStyle } from '../styles/typography'
import Button from './Button'
import { GridRow, GridCol } from './grid'
import RichText from './RichText'
import { CONTACT_US_REUSABLE_ROUTE } from '../utils/api.helper'
import * as Yup from 'yup'

interface IProps {
  formTitle: string
  items: any
  className?: string
  formImage: any
  formButtonLabel: string
  formBottomMessage: string
  emailReceiver: string
}

const ReusableContactForm: React.FC<IProps> = ({
  className,
  formTitle,
  items,
  formImage,
  formButtonLabel,
  formBottomMessage,
  emailReceiver,
}) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(file)
    })
  }

  const onFormSubmit = async (
    formData: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    let fileField

    const transformedFormData = Object.keys(formData).reduce((acc, key) => {
      const splitKey = key.split('_')
      const index = splitKey.length > 1 ? parseInt(splitKey[1]) : null

      if (index !== null && !isNaN(index) && items[index]) {
        if (items[index].field_type === 'Attachment') {
          fileField = formData[key]
        } else {
          acc[items[index].field_label] = formData[key]
        }
      }

      return acc
    }, {})

    if (fileField) {
      try {
        const base64File = await fileToBase64(fileField)
        transformedFormData['attachment'] = base64File
        transformedFormData['attachmentName'] = fileField.name
      } catch (error) {
        console.error('Error converting file:', error)
      }
    }

    const submissionData = {
      ...transformedFormData,
      emailReceiver,
    }

    const req = await fetch(CONTACT_US_REUSABLE_ROUTE, {
      method: 'POST',
      body: JSON.stringify(submissionData),
    })
    setIsDisabled(false)
    if (req.ok) {
      setResponseMessage('Great! Your request was successfuly submitted!')
      await formikHelpers.resetForm()
    } else {
      setResponseMessage(
        'Oh no! Something happened and we did not send your form request! Please try again, or use one of the other contact methods'
      )
    }
  }

  const createValidationSchema = (items) => {
    const schemaFields = items.reduce((acc, item, index) => {
      const fieldName = `${item.field_type}_${index}`

      switch (item.field_type) {
        case 'Email':
          acc[fieldName] = Yup.string()
            .required('Email is required.')
            .email('Email must be valid')
          break

        case 'Name':
          acc[fieldName] = Yup.string()
            .min(2, 'Name must be more than 2 characters long')
            .max(150, 'Name cannot exceed 150 characters')
          break

        case 'PhoneNumber':
          acc[fieldName] = Yup.string()
            .min(2, 'Name must be more than 2 characters long')
            .max(150, 'Name cannot exceed 150 characters')
          break

        case 'Notes':
          acc[fieldName] = Yup.string()
            .required('Notes is a required field.')
            .max(2000, 'Notes must be up to 2000 characters long.')
          break

        default:
          acc[fieldName] = Yup.string().max(
            2000,
            'Field must be up to 1000 characters long.'
          )
          break
      }

      return acc
    }, {})

    return Yup.object().shape(schemaFields)
  }

  const validationSchema = createValidationSchema(items)

  const initialValues = items.reduce((acc, item, index) => {
    const fieldName = `${item.field_type}_${index}`
    acc[fieldName] = ''
    return acc
  }, {})

  return (
    <StyledGridRow className={className}>
      <GridCol l={8} m={4} s={4}>
        <TextBox>
          <Title>{formTitle}</Title>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(val, formikHelpers) => onFormSubmit(val, formikHelpers)}
          >
            {({ submitForm, errors, handleChange, values, setFieldValue }) => {
              return (
                <Form style={{ margin: 0 }}>
                  {items.map((item, index) => {
                    const fieldName = `${item.field_type}_${index}`

                    return (
                      <React.Fragment key={index}>
                        <Label htmlFor={fieldName}>{item.field_label}</Label>
                        {(item.field_type === 'Email' ||
                          item.field_type === 'Name' ||
                          item.field_type === 'PhoneNumber' ||
                          item.field_type === 'Other') && (
                          <>
                            <TextInput
                              name={fieldName}
                              type="text"
                              onChange={handleChange}
                              value={values[fieldName]}
                              disabled={isDisabled}
                            />
                            {errors[fieldName] && (
                              <ErrorMessageBox>
                                {errors[fieldName] as string}
                              </ErrorMessageBox>
                            )}
                          </>
                        )}
                        {item.field_type === 'Notes' && (
                          <>
                            <TextArea
                              name={fieldName}
                              onChange={handleChange}
                              value={values[fieldName]}
                              disabled={isDisabled}
                            />
                            {errors[fieldName] && (
                              <ErrorMessageBox>
                                {errors[fieldName] as string}
                              </ErrorMessageBox>
                            )}
                          </>
                        )}
                        {item.field_type === 'Attachment' && (
                          <FileInput
                            name={`attachment_${index}`}
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                `attachment_${index}`,
                                event.currentTarget.files != null
                                  ? event.currentTarget.files[0]
                                  : null
                              )
                            }}
                            disabled={isDisabled}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                  {!responseMessage && (
                    <Button
                      type={'medium'}
                      textColor={'turqoise'}
                      disabled={isDisabled}
                      onClick={() => {
                        submitForm()
                      }}
                    >
                      {formButtonLabel}
                    </Button>
                  )}
                  {responseMessage && (
                    <InfoMessageBox>{responseMessage}</InfoMessageBox>
                  )}
                </Form>
              )
            }}
          </Formik>
          <BottomMessage>
            <RichText field={formBottomMessage} />
          </BottomMessage>
        </TextBox>
      </GridCol>
      <GridCol l={8} m={4} s={4}>
        <GatsbyImage
          image={formImage.gatsbyImageData}
          alt={formImage.alt ?? 'Image'}
          objectFit="cover"
        ></GatsbyImage>
      </GridCol>
    </StyledGridRow>
  )
}

const ErrorMessageBox = styled.div`
  ${applyTextStyle('subtle')};
  margin-bottom: 14px;
  color: ${colors.bordeauxRed};
`

const InfoMessageBox = styled.div`
  ${applyTextStyle('large')};
  margin-bottom: 14px;
  color: ${colors.mattePurple};
`

const StyledGridRow = styled(GridRow)`
  margin-top: 60px;
  ${mqTo.S`
    display: flex;
    flex-direction: column-reverse;
  `}
`

const TextInput = styled.input`
  ${applyTextStyle('normal')}
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 6px;
  rows: 4;
`

const TextArea = styled.textarea`
  ${applyTextStyle('normal')}
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px 10px;
`

const Label = styled.label`
  margin-bottom: 8px;
  color: ${colors.grey};
  ${applyTextStyle('large')};
`

const TextBox = styled.div`
  padding: 60px 80px 120px 75px;
  ${mqTo.S`
    padding: 30px 37px 80px 37px;
  `}
`

const Title = styled.div`
  ${applyTextStyle('titleNormal')};
  color: ${colors.turqoise};
  margin-bottom: 40px;
`

const BottomMessage = styled.div`
  ${applyTextStyle('subtle')};
  color: ${colors.grey};
  & a {
    color: ${colors.turqoise} !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
`

const FileInput = styled.input`
  ${applyTextStyle('normal')}
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 6px;
`

export default ReusableContactForm
