import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconAnalitics = (props: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 68.506 68.734"
  >
    <path
      id="_03_analytics_blk"
      data-name="03_analytics_blk"
      d="M597.8,597.62a34.036,34.036,0,0,1-20.452-7.068,34.717,34.717,0,0,1-12.08-17.479,1.313,1.313,0,0,1,.743-1.582l10.723-4.608,15.021-6.367a2.312,2.312,0,0,0,1.411-2.133V543.711a2.317,2.317,0,0,0-3.146-2.164,23.189,23.189,0,0,0-13.727,14.084,1.323,1.323,0,0,1-.723.78l-9.668,4.158a1.316,1.316,0,0,1-1.825-1.368,34.381,34.381,0,0,1,38.014-30.092,1.313,1.313,0,0,1,1.16,1.306V540.6l-.006.641v24.48a1.313,1.313,0,0,1-.8,1.21l-20.252,8.585a2.317,2.317,0,0,0-.779,3.727,22.993,22.993,0,0,0,16.793,7.21c.207,0,.416,0,.624-.008a23.189,23.189,0,0,0,14.936-40.384,1.316,1.316,0,0,1-.433-.973v-10.5a1.321,1.321,0,0,1,1.317-1.317,1.286,1.286,0,0,1,.647.176,34.361,34.361,0,0,1-17.091,64.173Z"
      transform="translate(-564.067 -528.89)"
      fill={props.color}
      opacity="0.997"
    />
  </svg>
)

export default IconAnalitics
