const API_URL = process.env.GATSBY_REACT_APP_API_URL || '/.netlify/functions'
export const CONTACT_US_ROUTE = API_URL + '/contact-form'
export const CONTACT_US_REUSABLE_ROUTE = API_URL + '/contact-form-reusable'

export const getPageName = (slug: string) => {
  if (slug === 'index') {
    return 'worldofwork - work made seamless'
  }
  return (
    ('' + slug).charAt(0).toUpperCase() +
    (slug + '').slice(1).replaceAll('-', ' ')
  )
}
