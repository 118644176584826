import React from 'react'
import styled from 'styled-components'

interface IProps {
  className?: string
  field: any
}

const tagsMap = {
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  heading5: 'h5',
  heading6: 'h6',
}

const PageTitle: React.FC<IProps> = ({ className, field }) => {
  const tag =
    Array.isArray(field) && field.length && field[0].type
      ? tagsMap[field[0].type]
      : 'div'
  return (
    <TitleComponent className={className} as={tag}>
      {typeof field === 'string' ? field : field[0]?.text}
    </TitleComponent>
  )
}

const TitleComponent = styled.div``

export default PageTitle
