import React from 'react'
import styled from 'styled-components'
import { SliceComponentProps } from '@prismicio/react'
import ArrowSlider from '../ArrowSlider'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { IPageTheme } from '../../pages/{PrismicPage.url}'

interface ISlice {
  primary: {
    title1: {
      richText: any
    }
  }
}

const CasesImageSliderSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const context = props.context as IPageTheme

  const staticData = useStaticQuery(graphql`
    query {
      allPrismicCas {
        nodes {
          _previewable
          lang
          alternate_languages {
            id
            type
            lang
            uid
          }
          data {
            cover_image {
              gatsbyImageData(placeholder: BLURRED)
              alt
            }
            description {
              richText
              text
            }
            page_link {
              url
              raw
              id
              link_type
            }
            title {
              text
            }
          }
        }
      }
    }
  `)
  const { data } = useMergePrismicPreviewData(staticData)

  const cards = data.allPrismicCas.nodes
    .filter((n: any) => n.lang === context.lang)
    .map((card: any) => {
      return {
        image: card.data.cover_image.gatsbyImageData,
        title: card.data.title.text,
        text: card.data.description.text,
        link: card.data.page_link,
        alt: card.data.cover_image.alt,
      }
    })

  return (
    <Wrapper>
      <ArrowSlider
        cards={cards}
        title={slice.primary.title1.richText}
      ></ArrowSlider>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

export default CasesImageSliderSlice
