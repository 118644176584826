import React from 'react'
import styled from 'styled-components'
import { SliceComponentProps } from '@prismicio/react'
import { mqFrom } from '../../styles/responsive'
import ReusableContactForm from '../ReusableContactForm'

interface ISlice {
  primary: {
    form_title: {
      text: string
    }
    form_image: any
    form_button_label: string
    form_bottom_message: {
      richText: string
    }
    email_receiver: string
  }
  items: IFormField[]
}

interface IFormField {
  field_type: string
  field_label: string
}

const PageReusableContactFormSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice

  return (
    <Wrapper>
      <ReusableContactForm
        formTitle={slice.primary.form_title.text}
        formImage={slice.primary.form_image}
        formBottomMessage={slice.primary.form_bottom_message.richText}
        formButtonLabel={slice.primary.form_button_label}
        items={slice.items}
        emailReceiver={slice.primary.email_receiver}
      ></ReusableContactForm>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 50px;
  width: 100%;
  ${mqFrom.S`
    margin-top: 200px;
    margin-bottom: 80px;
    display: block;
  `}
`

export default PageReusableContactFormSlice
