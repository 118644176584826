import React from 'react'
import styled from 'styled-components'
import { mqFrom } from '../styles/responsive'
import Slides from './Animations'

interface IProps {
  className?: string
  children: React.ReactNode
  withMobilePadding?: boolean
  largeContainer?: boolean
  animated?: boolean
}
const AppContainer: React.FC<IProps> = ({
  className,
  children,
  withMobilePadding,
  animated,
  largeContainer,
}) => (
  <Slides animated={animated}>
    <Wrapper
      className={`app-container ${className ?? ''}`}
      withMobilePadding={withMobilePadding}
      largeContainer={largeContainer}
    >
      {children}
    </Wrapper>
  </Slides>
)

const Wrapper = styled.div<{
  withMobilePadding?: boolean
  largeContainer?: boolean
}>`
  padding: ${({ withMobilePadding }) =>
    withMobilePadding ? '0 6.25vw;' : '0'};
  max-width: ${({ largeContainer }) => (largeContainer ? '2900px' : '2100px')};
  margin: 0 auto;
  ${({ largeContainer }) =>
    !largeContainer &&
    mqFrom.S`
    padding: 0 6.25vw;
  `}
`

export default AppContainer
