import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconMagnifyingGlass = (props: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 62 80"
  >
    <path
      id="_01_strategy_design"
      data-name="01_strategy_design"
      d="M616.752,544.2c-17.3-.031-29.309,11.787-29.359,28.9-.028,9.575,3.672,17.506,9.965,22.661l-12.593,18.918a4.961,4.961,0,0,0,1.347,6.857l2.719,1.829a4.893,4.893,0,0,0,6.815-1.356l13.7-20.573a34.871,34.871,0,0,0,7.205.781c17.183.114,29.291-11.8,29.384-28.913C646.022,556.236,633.984,544.23,616.752,544.2Zm-.1,46.511c-9.81-.019-16.74-7.232-16.77-17.446-.029-10.307,7.022-17.646,16.891-17.6,9.633.049,16.6,7.3,16.672,17.356C633.518,583.334,626.491,590.732,616.653,590.71Z"
      transform="translate(-583.933 -544.199)"
      fill={props.color}
    />
  </svg>
)

export default IconMagnifyingGlass
