import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import { IPageTheme } from '../../pages/{PrismicPage.url}'
import SectionsCard from '../cards/SectionsCards'

interface ISlice {
  items: {
    link: any
    section_description: {
      richText: string
    }
    section_title: {
      richText: string
    }
  }[]
  primary: {
    description: {
      richText: string
    }
    image_direction: 'Left' | 'Right'
    title: {
      richText: any
    }
    image: any
  }
}

const ServiceCardSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const context = props.context as unknown as IPageTheme

  const sectionTitles = slice.items.map(
    (item: { section_title: { richText: any } }) => item.section_title?.richText
  )
  const sectionDescriptions = slice.items.map(
    (item) => item.section_description.richText
  )
  const pageLinks = slice.items.map((item) => item.link)

  return (
    <SectionsCard
      title={slice.primary?.title?.richText || ''}
      description={slice.primary.description.richText}
      sectionTitles={sectionTitles}
      sectionDescriptions={sectionDescriptions}
      titleArrowColor={context.headersColor}
      contentColor={context.textColor}
      left={slice.primary.image_direction === 'Left' ? true : false}
      image={slice.primary.image}
      pageLinks={pageLinks}
    ></SectionsCard>
  )
}

export default ServiceCardSlice
