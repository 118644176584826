import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import AppContainer from '../AppContainer'
import { IconType } from '../icon/Icon'
import NGrid from '../NGrid'
import { IPrismicLink } from '../PageLink'
import TitleContent from '../TitleContent'

interface ISlice {
  items: {
    image: any
    title: {
      richText: any
    }
    content: {
      richText: any
    }
    icon: IconType | 'None'
    card_link: IPrismicLink
    background_color1: any
    title_color: any
    text_color: any
  }[]
  primary: {
    title: {
      richText: any
    }
  }
}

const ArrowCardGridSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  return (
    <TitleContent title={slice.primary?.title?.richText || ''}>
      <AppContainer largeContainer={true} animated={false}>
        <NGrid
          cardsInfo={slice.items.map((item) => ({
            image: item.image.gatsbyImageData,
            alt: item.image.alt,
            title: item.title.richText,
            titleColor: item.title_color,
            backgroundColor: item.background_color1,
            description: item.content.richText,
            descriptionColor: item.text_color,
            arrowColor: item.title_color,
            cardLink: item.card_link,
            iconColor: item.title_color,
            iconType: item.icon !== 'None' ? item.icon : undefined,
            type: item.icon === 'None' ? 'NoIcon' : 'WithIcon',
          }))}
        ></NGrid>
      </AppContainer>
    </TitleContent>
  )
}

export default ArrowCardGridSlice
