import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowRight = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 124 53.592"
  >
    <g
      id="Group_5548"
      data-name="Group 5548"
      transform="translate(-479.117 -1143.592)"
    >
      <path
        id="Path_761"
        data-name="Path 761"
        d="M7.5,18h120"
        transform="translate(473.617 1152.387)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <path
        id="Path_762"
        data-name="Path 762"
        d="M18,7.5l17.622,24L18,55.5"
        transform="translate(565.495 1138.888)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </g>
  </svg>
)

export default IconArrowRight
