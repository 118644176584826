import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../styles/colors'
import { mqFrom } from '../styles/responsive'

import { applyTextStyle } from '../styles/typography'
import { Icon } from './icon'
import Slides from './Animations'

export type ButtonTypes = 'small' | 'medium' | 'large' | 'arrow'

interface IProps {
  className?: string
  type: ButtonTypes
  mode?: 'button' | 'link'
  onClick?: () => void
  navigateTo?: string
  textColor?: keyof typeof colors
  textColorHex?: string
  children?: React.ReactNode
  disabled?: boolean
}
const Button: React.FC<IProps> = ({
  children,
  className,
  textColor,
  textColorHex,
  type,
  onClick,
  disabled,
}) => {
  const color =
    textColorHex || (textColor ? colors[textColor] : colors.brightBlue)
  const getButtonHeight = (buttonType: ButtonTypes) => {
    if (buttonType === 'small') {
      return 58
    }
    if (buttonType === 'medium') {
      return 116
    }
    if (buttonType === 'large') {
      return 116
    }
    return 10
  }
  return (
    <Slides>
      <Wrapper className={className} onClick={onClick} disabled={disabled}>
        <TextContent type={type} color={color} content={true}>
          {children}
          <StyledArrow
            type="ArrowRight"
            buttonType={type}
            size={getButtonHeight(type)}
            color={color}
          ></StyledArrow>
          <BottomLine buttonType={type} color={color}></BottomLine>
        </TextContent>
      </Wrapper>
    </Slides>
  )
}

const Wrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
`

const TextContent = styled.div<{
  type: ButtonTypes
  color: string
  content: boolean
}>`
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: ${({ color }) => color};
  text-align: left;
  ${({ type, content }) => {
    if (content && type === 'small') {
      return css`
        ${applyTextStyle('subtle')};
        padding-right: 32px;
        padding-bottom: 32px;
      `
    }
    if (content && type === 'medium') {
      return css`
        ${applyTextStyle('large')};
        padding-right: 100px;
        padding-bottom: 65px;
      `
    }
    if (content && type === 'large') {
      return css`
        ${applyTextStyle('titleLargeRegular')};
        padding-right: 40px;
        padding-bottom: 65px;
        ${mqFrom.S`
          padding-right: 100px;
        `}
      `
    }
    return applyTextStyle('normal')
  }}
`

const StyledArrow = styled(Icon)<{ buttonType: ButtonTypes }>`
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  margin: 0;
`

const BottomLine = styled.div<{ buttonType: ButtonTypes; color: string }>`
  position: absolute;
  left: 0;
  width: calc(100% - 3px);
  background-color: ${({ color }) => color};
  ${({ buttonType }) => {
    if (buttonType === 'small') {
      return css`
        height: 2px;
        bottom: 28px;
      `
    }
    if (buttonType === 'medium') {
      return css`
        bottom: calc(116px / 2 - 2px);
        height: 4px;
      `
    }
    if (buttonType === 'large') {
      return css`
        bottom: calc(116px / 2 - 2px);
        height: 4px;
      `
    }
    return applyTextStyle('normal')
  }}
`

export default Button
