import { Form, Formik, FormikHelpers } from 'formik'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import { mqTo } from '../styles/responsive'
import { applyTextStyle } from '../styles/typography'
import Button from './Button'
import { GridRow, GridCol } from './grid'
import RichText from './RichText'
import * as Yup from 'yup'
import { CONTACT_US_ROUTE } from '../utils/api.helper'

interface IProps {
  className?: string
  bottomMessage: string
  formTitle: string
  image: any
  nameLabel: string
  notesLabel: string
  phoneLabel: string
  buttonLabel: string
  emailLabel: string
}

const ContactForm: React.FC<IProps> = ({
  className,
  bottomMessage,
  formTitle,
  image,
  nameLabel,
  notesLabel,
  phoneLabel,
  buttonLabel,
  emailLabel,
}) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .min(2, 'Name must be more than 2 characters long')
        .max(150, 'Name cannot exceed 150 characters'),
      email: Yup.string().email('Email must be valid'),
      phone: Yup.string()
        .max(100, 'Phone cannot be longer than 100 characters')
        .when('email', {
          is: (email) => !email,
          then: Yup.string().required(
            'Provide at least one way to contact you back - phone or email'
          ),
        }),
      notes: Yup.string()
        .required('Notes is a required field.')
        .max(2000, 'Notes must be up to 2000 characters long.'),
    },
    [['email', 'phone']]
  )

  const onFormSubmit = async (
    formData: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    if (isDisabled) {
      return
    }
    setResponseMessage('')
    setIsDisabled(true)
    const req = await fetch(CONTACT_US_ROUTE, {
      method: 'POST',
      body: JSON.stringify(formData),
    })
    setIsDisabled(false)
    if (req.ok) {
      setResponseMessage('Great! Your request was successfuly submitted!')
      await formikHelpers.resetForm()
    } else {
      setResponseMessage(
        'Oh no! Something happened and we did not send your form request! Please try again, or use one of the other contact methods'
      )
    }
  }

  return (
    <StyledGridRow className={className}>
      <GridCol l={8} m={4} s={4}>
        <TextBox>
          <Title>{formTitle}</Title>
          <Formik
            initialValues={{ name: '', email: '', phone: '', notes: '' }}
            validationSchema={validationSchema}
            onSubmit={(val, formikHelpers) => onFormSubmit(val, formikHelpers)}
          >
            {({ submitForm, errors, handleChange, values }) => {
              return (
                <Form style={{ margin: 0 }}>
                  <Label htmlFor="name">{nameLabel}</Label>
                  <TextInput
                    name="name"
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    disabled={isDisabled}
                  />
                  {errors.name && (
                    <ErrorMessageBox>{errors.name}</ErrorMessageBox>
                  )}

                  <Label htmlFor="email">{emailLabel}</Label>
                  <TextInput
                    name="email"
                    type="text"
                    onChange={handleChange}
                    value={values.email}
                    disabled={isDisabled}
                  />
                  {errors.email && (
                    <ErrorMessageBox>{errors.email}</ErrorMessageBox>
                  )}

                  <Label htmlFor="phone">{phoneLabel}</Label>
                  <TextInput
                    name="phone"
                    type="phone"
                    onChange={handleChange}
                    value={values.phone}
                    disabled={isDisabled}
                  />
                  {errors.phone && (
                    <ErrorMessageBox>{errors.phone}</ErrorMessageBox>
                  )}

                  <Label htmlFor="notes">{notesLabel}</Label>
                  <TextArea
                    name="notes"
                    onChange={handleChange}
                    value={values.notes}
                    disabled={isDisabled}
                  ></TextArea>
                  {errors.notes && (
                    <ErrorMessageBox>{errors.notes}</ErrorMessageBox>
                  )}
                  {!responseMessage && (
                    <Button
                      type={'medium'}
                      textColor={'turqoise'}
                      disabled={isDisabled}
                      onClick={() => {
                        submitForm()
                      }}
                    >
                      {buttonLabel}
                    </Button>
                  )}
                  {responseMessage && (
                    <InfoMessageBox>{responseMessage}</InfoMessageBox>
                  )}
                </Form>
              )
            }}
          </Formik>
          <BottomMessage>
            <RichText field={bottomMessage} />
          </BottomMessage>
        </TextBox>
      </GridCol>
      <GridCol l={8} m={4} s={4}>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ?? 'Image'}
          objectFit="cover"
        ></GatsbyImage>
      </GridCol>
    </StyledGridRow>
  )
}

const ErrorMessageBox = styled.div`
  ${applyTextStyle('subtle')};
  margin-bottom: 14px;
  color: ${colors.bordeauxRed};
`

const InfoMessageBox = styled.div`
  ${applyTextStyle('large')};
  margin-bottom: 14px;
  color: ${colors.mattePurple};
`

const StyledGridRow = styled(GridRow)`
  margin-top: 160px;
  ${mqTo.S`
    display: flex;
    flex-direction: column-reverse;
  `}
`

const TextInput = styled.input`
  ${applyTextStyle('normal')}
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 6px;
  rows: 4;
`

const TextArea = styled.textarea`
  ${applyTextStyle('normal')}
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px 10px;
`

const Label = styled.label`
  margin-bottom: 8px;
  color: ${colors.grey};
  ${applyTextStyle('large')};
`

const TextBox = styled.div`
  padding: 60px 80px 120px 75px;
  ${mqTo.S`
    padding: 30px 37px 80px 37px;
  `}
`

const Title = styled.div`
  ${applyTextStyle('titleNormal')};
  color: ${colors.turqoise};
  margin-bottom: 40px;
`

const BottomMessage = styled.div`
  ${applyTextStyle('subtle')};
  color: ${colors.grey};
  & a {
    color: ${colors.turqoise} !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
`

export default ContactForm
