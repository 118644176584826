import React from 'react'
import styled from 'styled-components'
import { Color } from '../../common/models/colors'
import { mqTo } from '../../styles/responsive'
import AppContainer from '../AppContainer'
import Button from '../Button'
import { GridCol } from '../grid'
import GridRow from '../grid/GridRow'
import { GatsbyImage } from 'gatsby-plugin-image'
import colors from '../../styles/colors'
import PageLink from '../PageLink'
import { applyTextStyle } from '../../styles/typography'
import PageTitle from '../PageTitle'

interface IProps {
  className?: string
  image: any
  description: any
  descriptionColor?: string
  arrowText: string
  arrowColor?: Color
  arrowLink: any
  title: string
}

const ArrowButtonCard: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledAppContainer className={props.className} withMobilePadding={false}>
      <StyledGridRow>
        <GridCol l={8} m={4} s={4}>
          <StyledGatsbyImage
            image={props.image.gatsbyImageData}
            alt={props.image.alt ?? 'image'}
            objectFit="cover"
            style={{
              width: '100%',
              margin: '0',
            }}
          ></StyledGatsbyImage>
        </GridCol>
        <GridCol l={8} m={4} s={4}>
          <Title field={props.title}></Title>
          <TextContentBox>
            <Description color={props.descriptionColor ?? colors.grey}>
              {props.description[0].text}
            </Description>
            {props.arrowLink.url !== null && (
              <PageLink link={props.arrowLink}>
                <StyledButton type="medium" textColor={props.arrowColor}>
                  {props.arrowText}
                </StyledButton>
              </PageLink>
            )}
          </TextContentBox>
        </GridCol>
      </StyledGridRow>
    </StyledAppContainer>
  )
}

const Title = styled(PageTitle)`
  ${applyTextStyle('titleLoud')};
`

const StyledButton = styled(Button)`
  margin-top: 24px;
`

const StyledGridRow = styled(GridRow)`
  ${mqTo.S`
      flex-direction: column;
  `}
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
`

const StyledAppContainer = styled(AppContainer)`
  margin-top: 160px;
`

const Description = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`

const TextContentBox = styled.div`
  ${mqTo.S`
    padding: 0 20px;
  `}
  padding-right: 120px;
  height: 100%;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  ${mqTo.S`
    margin-bottom: 24px;
  `}
`

export default ArrowButtonCard
