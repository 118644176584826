// tslint:disable: interface-over-type-literal */
import { createContext, useContext } from 'react'
import React, { useReducer } from 'react'

// Types
import GlobalStyles from '../styles'
import { Helmet } from 'react-helmet'

// Utils
import '../styles/global.css'

export interface ThemeType {
  bodyBackground: string
  isFooterTransparent: boolean
}

export interface IOptionsPopup {
  coordinates: {
    x: number
    y: number
  }
}

// Types
export type State = {
  app: boolean
}

export const DEFAULT_APP_STATE: State = {
  app: true,
}

export type Action = void

export type Dispatch = (action: Action) => void

// Contexts
export const AppStateCtx = createContext<State>({ ...DEFAULT_APP_STATE })

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AppDispatchCtx = createContext<Dispatch>((_: Action) => {
  return
})

// Reducer
export const appReducer = () => {
  return {
    app: true,
  }
}

// Hooks
export const useAppState = () => {
  const context = useContext(AppStateCtx)

  if (!context) {
    throw new Error('useAppState must be used within a AppProvider')
  }

  return context
}

export const useAppDispatch = () => {
  const context = useContext(AppDispatchCtx)

  if (!context) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }

  return context
}

export const ContextTemplate: React.FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, { ...DEFAULT_APP_STATE })

  return (
    <AppStateCtx.Provider value={state}>
      <AppDispatchCtx.Provider value={dispatch}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=5"
          />
          <script>
            {`(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('p1e024BvJGv4GB6d');`}
          </script>
        </Helmet>
        <GlobalStyles />
        <>{children}</>
      </AppDispatchCtx.Provider>
    </AppStateCtx.Provider>
  )
}
