import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { applyTextStyle } from '../../styles/typography'
import PageTitle from '../PageTitle'

interface IProps {
  className?: string
  image: any
  title: any
  description: string
  alt?: string
}

const LargeImageCard: React.FC<IProps> = ({
  className,
  image,
  title,
  description,
  alt,
}) => (
  <Wrapper className={className}>
    <GatsbyImage
      image={image}
      alt={alt ?? 'Card'}
      objectFit="cover"
    ></GatsbyImage>
    <Title field={title}></Title>
    <Description>{description}</Description>
  </Wrapper>
)

const Wrapper = styled.div`
  width: 100%;
  & img {
    transition: all 0.2s ease-in-out !important;
  }
  &:hover {
    & img {
      transform: scale(1.1);
    }
  }
`

const Title = styled(PageTitle)`
  ${applyTextStyle('titleSubtle')};
  color: ${colors.grey};
  margin-top: 40px;
  margin-bottom: 16px;
`

const Description = styled.div`
  ${applyTextStyle('normal')};
  color: ${colors.grey};
`

export default LargeImageCard
