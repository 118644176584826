import React from 'react'
import styled from 'styled-components'
import { GridCol, GridRow } from './grid'
import { applyTextStyle } from '../styles/typography'
import { mqFrom } from '../styles/responsive'
import { Icon, IconFooterLogo } from './icon'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import Slides from './Animations'
import PageLink from './PageLink'
import { getPageName } from '../utils/api.helper'

interface IProps {
  className?: string
  logoColor: string
  lang?: string
}

const Footer: React.FC<IProps> = ({ className, logoColor, lang }) => {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicFooter {
        edges {
          node {
            _previewable
            lang
            data {
              copyright
              email
              body {
                ... on PrismicFooterDataBodyFooterLinksSet {
                  id
                  primary {
                    title_link {
                      raw
                    }
                    title1 {
                      text
                    }
                  }
                  items {
                    link {
                      url
                      raw
                      id
                      link_type
                    }
                  }
                }
              }
              bottom_left_link {
                url
                raw
                id
                link_type
              }
              instagram {
                url
                raw
                id
                target
                link_type
              }
              linkedin {
                url
                raw
                id
                target
                link_type
              }
            }
          }
        }
      }
    }
  `)

  const merge = useMergePrismicPreviewData(staticData)
  const data = merge.data.allPrismicFooter.edges.find(
    (e: any) => e.node.lang === lang
  )

  return (
    <Slides>
      <Wrapper className={className}>
        <FooterContainer>
          <StyledGridRow lineColor={logoColor}>
            <GridCol l={5} m={8} s={4}>
              <StyledIconFooterLogo
                width={354}
                height={44.147}
                color={logoColor}
              ></StyledIconFooterLogo>
              <Email>{data.node.data.email}</Email>
            </GridCol>
            <GridCol l={1} m={0} s={0}></GridCol>
            <ContentCol l={10} m={8} s={4}>
              {data.node.data.body.map((item: any) => (
                <Container key={'footer-container' + item.id}>
                  <LargeText>{item.primary.title1.text}</LargeText>
                  {item.items.map((footerLink: any) => (
                    <LinkText key={'footer-link' + footerLink.link.raw.id}>
                      <PageLink link={footerLink.link}>
                        {getPageName(footerLink.link.raw.uid)}
                      </PageLink>
                    </LinkText>
                  ))}
                </Container>
              ))}
            </ContentCol>
          </StyledGridRow>
          <BottomGridRow>
            <GridCol l={6} m={3} s={4}>
              <LinkText>
                <PageLink link={data.node.data.bottom_left_link}>
                  Privacy Policy
                </PageLink>
              </LinkText>
            </GridCol>
            <CenterCol l={4} m={2} s={4}>
              <LinkText>
                {data.node.data.copyright.replace(
                  '{currentYear}',
                  new Date().getFullYear()
                )}
              </LinkText>
            </CenterCol>
            <RightCol l={6} m={3} s={4}>
              <LinkText>
                {data.node.data.instagram?.url && (
                  <PageLink link={data.node.data.instagram}>
                    <Icon type="Instagram" size={39} color={logoColor} />
                  </PageLink>
                )}
                <PageLink link={data.node.data.linkedin}>
                  <MarginIcon type="LinkedIn" size={39} color={logoColor} />
                </PageLink>
              </LinkText>
            </RightCol>
          </BottomGridRow>
        </FooterContainer>
      </Wrapper>
    </Slides>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 220px;
`

const FooterContainer = styled.div`
  padding: 0 3.25vw 24px;
  max-width: 2100px;
  margin: 0 auto;
`

const StyledGridRow = styled(GridRow)<{ lineColor: string }>`
  border-top: 2px solid ${({ lineColor }) => lineColor};
  padding-top: 24px;
`

const Email = styled.div`
  ${applyTextStyle('normal')};
  margin-top: 8px;
  margin-bottom: 40px;
`

const LargeText = styled.div`
  ${applyTextStyle('normal', 'desktop')};
  padding-right: 11px;
  margin-top: 30px;
  margin-bottom: 14px;
  ${mqFrom.S`
    margin-bottom: 32px;
    margin-top: 0;
  `}
`

const LinkText = styled.div`
  ${applyTextStyle('subtle')};
  margin-bottom: 21px;
  cursor: pointer;
`

const ContentCol = styled(GridCol)`
  justify-content: space-between;
  ${mqFrom.S`
    display: flex;
  `}
`

const Container = styled.div`
  margin: 0 8px;
`

const BottomGridRow = styled(GridRow)`
  text-align: center;
  margin-top: 40px;
  align-items: center;
  ${mqFrom.S`
    text-align: left;
    margin-top: 140px;
  `}
`

const RightCol = styled(GridCol)`
  ${mqFrom.S`
    text-align: right;
  `}
`

const CenterCol = styled(GridCol)`
  ${mqFrom.S`
    text-align: center;
  `}
`

const MarginIcon = styled(Icon)`
  margin-left: 22px;
`

const StyledIconFooterLogo = styled(IconFooterLogo)`
  max-width: 100%;
`

export default Footer
