import React, { useState } from 'react'
import { GridRow, GridCol } from './grid'
import styled from 'styled-components'
import { Icon } from './icon'
import { applyTextStyle } from '../styles/typography'
import AppContainer from './AppContainer'
import { mqFrom } from '../styles/responsive'
import Button from './Button'
import PageLink from './PageLink'
import PageTitle from './PageTitle'

interface IProps {
  className?: string
  leftTexts: string[]
  centerTexts: string[]
  backgroundColors: string[]
  contentColors: string[]
  links: any[]
  arrowButtonText?: string
  arrowButtonLink?: any
  dropdownContents: string[]
  linkTexts: string[]
}

const Dropdowns: React.FC<IProps> = (props: IProps) => {
  const [openTab, setOpenTab] = useState<number | null>(null)

  const toggleTab = (index: number) => {
    if (openTab === index) {
      setOpenTab(null)
    } else {
      setOpenTab(index)
    }
  }

  return (
    <AppContainer className={props.className}>
      {props.leftTexts.map((leftText, index) => (
        <Tab key={index} color={props.backgroundColors[index]}>
          <Clickable onClick={() => toggleTab(index)}>
            <GridRow>
              <GridCol l={8} m={4} s={4}>
                <LeftContent
                  color={props.contentColors[index]}
                  field={leftText}
                ></LeftContent>
              </GridCol>
              <GridCol l={6} m={4} s={2}>
                <CenterContent
                  color={props.contentColors[index]}
                  field={props.centerTexts[index]}
                ></CenterContent>
              </GridCol>
              <GridCol l={1} m={8} s={2}>
                <StyledIcon
                  type="ArrowDown"
                  color={props.contentColors[index]}
                  width={100}
                  height={48}
                  size={0}
                  isOpen={openTab === index}
                ></StyledIcon>
              </GridCol>
            </GridRow>
          </Clickable>
          <Dropdown isOpen={openTab === index}>
            <DropdownContent
              color={props.backgroundColors[index]}
              textColor={props.contentColors[index]}
              field={props.dropdownContents[index]}
            ></DropdownContent>
            {props.links[index].url !== null && (
              <PageLink link={props.links[index]}>
                <JobButton
                  type="small"
                  textColorHex={props.contentColors[index]}
                >
                  {props.linkTexts[index]}
                </JobButton>
              </PageLink>
            )}
          </Dropdown>
        </Tab>
      ))}
      {props.arrowButtonText && props.arrowButtonLink && (
        <PageLink link={props.arrowButtonLink}>
          <StyledButton type="medium" textColor="brightBlue">
            {props.arrowButtonText}
          </StyledButton>
        </PageLink>
      )}
    </AppContainer>
  )
}

const Clickable = styled.div`
  cursor: pointer;
`

const StyledButton = styled(Button)`
  margin-top: 24px;
`

const JobButton = styled(Button)`
  ${mqFrom.M`
    padding: 0 100px 0 80px;
    margin-top: 26px;
  `}
`

const StyledIcon = styled(Icon)<{ isOpen: boolean }>`
  ${mqFrom.S`
    width: 100%;
  `}
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`

const Tab = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  margin-bottom: 8px;
  padding: 30px 39px;
  ${mqFrom.M`
    padding-top: 34px;
    padding-bottom: 12px;
  `}
`

const Text = styled(PageTitle)<{ color: string }>`
  color: ${({ color }) => color};
  ${applyTextStyle('large', 'desktop')};
  margin-bottom: 0px;
`

const DropdownContent = styled(Text)<{ color: string; textColor: string }>`
  ${mqFrom.M`
    padding: 0 100px 0 80px;
  `}
  background-color: ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  font-size: 24px;
  margin-top: 8px;
`

const Dropdown = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  overflow: hidden;
`
// transition: max-height 0.2s ease-in-out;

const LeftContent = styled(Text)`
  ${mqFrom.M`
    padding: 0 30px 0 80px;
 `}
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 50px;
`

const CenterContent = styled(Text)`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 50px;
`

export default Dropdowns
