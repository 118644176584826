import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import ExpertsCard from '../cards/ExpertsCard'
import { IPrismicLink } from '../PageLink'

interface ISlice {
  primary: {
    description: {
      richText: string
    }
    image: any
    title: {
      richText: string
    }
    bottom_link_text: string
    bottom_link: IPrismicLink
  }
}

const LargeImageCardSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice

  return (
    <ExpertsCard
      image={slice.primary.image}
      title={slice.primary.title.richText}
      description={slice.primary.description.richText}
      bottomLinkText={slice.primary.bottom_link_text}
      bottomLink={slice.primary.bottom_link}
    ></ExpertsCard>
  )
}

export default LargeImageCardSlice
