import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowLeft = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 124 53.592"
  >
    <g id="Group_5549" data-name="Group 5549" transform="translate(2 2.796)">
      <path
        id="Path_761"
        data-name="Path 761"
        d="M127.5,18H7.5"
        transform="translate(-7.5 5.999)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <path
        id="Path_762"
        data-name="Path 762"
        d="M35.622,7.5,18,31.5l17.622,24"
        transform="translate(-18 -7.5)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </g>
  </svg>
)

export default IconArrowLeft
