import { css } from 'styled-components'
import {
  ITextRules,
  ITextStyle,
  TextStyleType,
  FontWeight,
  FontFamily,
} from '../common/models/typography'
import { Breakpoint } from '../styles/responsive'
import { applyResponsive } from './responsive'

/**
 * Configure the text style presets
 */
export const textStyles = {
  titleLoud: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Heavy,
      fontSize: 48,
      lineHeight: 64,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Heavy,
      fontSize: 75,
      lineHeight: 96,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Heavy,
      fontSize: 95,
      lineHeight: 112,
    },
  },
  titleLarge: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 48,
      lineHeight: 56,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.ExtraBold,
      fontSize: 64,
      lineHeight: 72,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.ExtraBold,
      fontSize: 75,
      lineHeight: 96,
    },
  },
  titleLargeRegular: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 48,
      lineHeight: 56,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 64,
      lineHeight: 72,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 88,
      lineHeight: 96,
    },
  },
  titleNormal: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.ExtraBold,
      fontSize: 32,
      lineHeight: 40,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.ExtraBold,
      fontSize: 40,
      lineHeight: 48,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.ExtraBold,
      fontSize: 50,
      lineHeight: 72,
    },
  },
  titleNormalRegular: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 32,
      lineHeight: 40,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 40,
      lineHeight: 48,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 64,
      lineHeight: 72,
    },
  },
  titleSubtle: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.ExtraBold,
      fontSize: 24,
      lineHeight: 28,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.ExtraBold,
      fontSize: 24,
      lineHeight: 32,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.ExtraBold,
      fontSize: 40,
      lineHeight: 48,
    },
  },
  intro: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 24,
      lineHeight: 32,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 32,
      lineHeight: 40,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 40,
      lineHeight: 48,
    },
  },
  large: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 20,
      lineHeight: 28,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 24,
      lineHeight: 32,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 32,
      lineHeight: 40,
    },
  },
  normal: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 20,
      lineHeight: 24,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 20,
      lineHeight: 28,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 24,
      lineHeight: 32,
    },
  },
  subtle: {
    mobile: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 16,
      lineHeight: 24,
    },
    tablet: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 16,
      lineHeight: 24,
    },
    desktop: {
      fontFamily: FontFamily.Nexa,
      fontWeight: FontWeight.Regular,
      fontSize: 18,
      lineHeight: 24,
    },
  },
}

export const defaultTextStyle: TextStyleType = 'normal'

/**
 * Define which breakpoints to use to toggle between desktop/tablet/mobile
 * text styles
 */
export const typographyBreakpointTablet = Breakpoint.S
export const typographyBreakpointDesktop = Breakpoint.L

/**
 * Create the needed CSS for a given text style (works like a mixin)
 * @param textStyle The ITextStyle object, containing desktop styles
 * and optionally, mobile ones
 */
const applyCustomTextStyle = (
  textStyle: ITextStyle,
  forcedSize?: 'mobile' | 'tablet' | 'desktop'
) => {
  if (forcedSize) {
    return css`
      ${getTextRules(textStyle[forcedSize]!)}
    `
  }
  return css`
    ${getTextRules(textStyle.mobile)}
    ${textStyle.tablet &&
    applyResponsive(
      { from: typographyBreakpointTablet },
      getTextRules(textStyle.tablet)
    )}
    ${textStyle.desktop &&
    applyResponsive(
      { from: typographyBreakpointDesktop },
      getTextRules(textStyle.desktop)
    )}
  `
}

/**
 * Return a FlattenSimpleInterpolation css`` literal for given
 * text rules
 * @param rules The ITextRules object
 */
const getTextRules = (rules: ITextRules, scaleDown?: number) => {
  return css`
    font-size: ${rules.fontSize * (scaleDown ?? 1)}px;
    line-height: ${rules.lineHeight * (scaleDown ?? 1)}px;
    ${rules.fontFamily !== undefined &&
    css`
      font-family: ${rules.fontFamily};
    `}
    ${rules.fontWeight !== undefined &&
    css`
      font-weight: ${rules.fontWeight};
    `}
    ${rules.textTransform !== undefined &&
    css`
      text-transform: ${rules.textTransform};
    `}
    ${rules.fontStyle !== undefined &&
    css`
      font-style: ${rules.fontStyle};
    `}
    ${rules.textDecoration !== undefined &&
    css`
      text-decoration: ${rules.textDecoration};
    `}
    ${rules.letterSpacing !== undefined &&
    css`
      letter-spacing: ${rules.letterSpacing}em;
    `}
  `
}

/**
 * Apply a predefined text style (works like applyCustomTextStyle, but
 * takes as input a preset name, instead of an ITextRules object)
 * @param name The text style key, eg. 'caption' or 'labelHeavy'
 */
const applyTextStyle = (
  name: TextStyleType,
  forcedSize?: 'mobile' | 'desktop' | 'tablet'
) => {
  const textStyle = textStyles[name]
  return applyCustomTextStyle(textStyle, forcedSize)
}

/**
 * Apply ellipsis-style text overflow
 */
const applyTextEllipsis = () => {
  return css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
}

/**
 * Remove text ellipsis style (unset certain properties)
 */
const removeTextEllipsis = () => {
  return css`
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  `
}

export {
  Breakpoint,
  applyCustomTextStyle,
  applyTextStyle,
  applyTextEllipsis,
  removeTextEllipsis,
}
