import React from 'react'
import { GridRow, GridCol } from './grid'
import styled from 'styled-components'
import { Icon } from './icon'
import { applyTextStyle } from '../styles/typography'
import AppContainer from './AppContainer'
import { mqFrom } from '../styles/responsive'
import Button from './Button'
import PageLink from './PageLink'
import PageTitle from './PageTitle'

interface IProps {
  className?: string
  leftTexts: string[]
  centerTexts: string[]
  backgroundColors: string[]
  contentColors: string[]
  links: any[]
  arrowButtonText?: string
}

const Tabs: React.FC<IProps> = (props: IProps) => {
  return (
    <AppContainer className={props.className}>
      {props.leftTexts.map((leftText, index) => (
        <PageLink link={props.links[index]} key={index}>
          <Tab color={props.backgroundColors[index]}>
            <GridRow>
              <GridCol l={8} m={4} s={4}>
                <LeftContent
                  color={props.contentColors[index]}
                  field={leftText}
                ></LeftContent>
              </GridCol>
              <GridCol l={6} m={4} s={4}>
                <Text
                  color={props.contentColors[index]}
                  field={props.centerTexts[index]}
                ></Text>
              </GridCol>
              <GridCol l={1} m={8} s={4}>
                <StyledIcon
                  type="ArrowRight"
                  color={props.contentColors[index]}
                  width={100}
                  height={48}
                  size={0}
                ></StyledIcon>
              </GridCol>
            </GridRow>
          </Tab>
        </PageLink>
      ))}
      {props.arrowButtonText && (
        <StyledButton type="medium" textColor="brightBlue">
          {props.arrowButtonText}
        </StyledButton>
      )}
    </AppContainer>
  )
}

const StyledButton = styled(Button)`
  margin-top: 24px;
`

const StyledIcon = styled(Icon)`
  ${mqFrom.S`
    width: 100%;
  `}
`

const Tab = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  margin-bottom: 8px;
  padding: 30px 39px;
  ${mqFrom.M`
    padding: 34px 0;
  `}
`

const Text = styled(PageTitle)<{ color: string }>`
  color: ${({ color }) => color};
  ${applyTextStyle('large', 'desktop')};
  margin-bottom: 0px;
`

const LeftContent = styled(Text)`
  ${mqFrom.M`
    padding: 0 30px 0 80px;
 `}
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 50px;
`

// const StyledArrow = styled(Button)<{ color: string }>`
//   type="ArrowRight"
//   color={props.contentColors[index]}
// `

export default Tabs
