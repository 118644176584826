import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import Tabs from '../Tabs'
import TitleContent from '../TitleContent'

interface ISlice {
  items: {
    background_color1: string
    content_color: string
    center_text: {
      richText: string
    }
    left_text: {
      richText: string
    }
    link: any
  }[]
  primary: {
    bottom_button_label: string
    title: {
      richText: string
    }
  }
}

const ColoredArrowListSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const leftTexts = slice.items.map((item) => item.left_text.richText)
  const centerTexts = slice.items.map((item) => item.center_text.richText)
  const backgroundColors = slice.items.map((item) => item.background_color1)
  const links = slice.items.map((item) => item.link)
  const contentColors = slice.items.map((item) => item.content_color)

  return (
    <TitleContent title={slice.primary.title.richText}>
      <Tabs
        leftTexts={leftTexts}
        centerTexts={centerTexts}
        backgroundColors={backgroundColors}
        contentColors={contentColors}
        links={links}
        arrowButtonText={slice.primary.bottom_button_label}
      ></Tabs>
    </TitleContent>
  )
}

export default ColoredArrowListSlice
