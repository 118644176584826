import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { mqFrom } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import Button from '../Button'
import PageLink from '../PageLink'
import RichText from '../RichText'

interface IProps {
  className?: string
  wowCard: IWorkAtWowCard
}

export interface IWorkAtWowCard {
  imageSrc: any
  title: string
  description: string
  arrowText: string
  backgroundColor: string
  elementsColor: string
  pageLink: any
}

const WorkAtWoWCard: React.FC<IProps> = ({ className, wowCard }) => {
  return (
    <PageLink link={wowCard.pageLink}>
      <CardWrapper className={className} color={wowCard.backgroundColor}>
        <GatsbyImage
          image={wowCard.imageSrc}
          alt={'image'}
          objectFit="cover"
          style={{
            width: '100%',
          }}
        ></GatsbyImage>
        <ElementsWrapper>
          <Title color={wowCard.elementsColor}>{wowCard.title}</Title>
          <Description color={wowCard.elementsColor}>
            <RichText field={wowCard.description}></RichText>
          </Description>
          <Button type="small" textColorHex={wowCard.elementsColor}>
            {wowCard.arrowText}
          </Button>
        </ElementsWrapper>
      </CardWrapper>
    </PageLink>
  )
}

const ElementsWrapper = styled.div`
  margin: -30px 24px 0;
  ${mqFrom.M`
    margin: -50px 64px 0;
  `}
`

const CardWrapper = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  padding-bottom: 40px;
`

const Title = styled.div<{ color: string }>`
  ${applyTextStyle('titleSubtle')};
  color: ${({ color }) => color};
  margin-bottom: 24px;
  max-width: 68%;
`

const Description = styled.div<{ color: string }>`
  ${applyTextStyle('normal')};
  color: ${({ color }) => color};
  margin-bottom: 40px;
`

export default WorkAtWoWCard
