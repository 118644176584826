import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { mqFrom } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import { GridCol } from '../grid'
import GridRow from '../grid/GridRow'
import { Icon } from '../icon'
import { IconType } from '../icon/Icon'
import PageLink, { IPrismicLink } from '../PageLink'
import PageTitle from '../PageTitle'
import RichText from '../RichText'

export interface IProps {
  image: any
  title: any
  titleColor: string
  backgroundColor: string
  left?: boolean
  description: string
  descriptionColor: string
  arrowColor: string
  type: 'WithIcon' | 'NoIcon'
  iconColor?: string
  iconType?: IconType
  cardLink: IPrismicLink
  alt: string
}

const ArrowCard: React.FC<IProps> = (props: IProps) => {
  return (
    // eslint-disable-next-line react/jsx-no-duplicate-props
    <Wrapper>
      {props.cardLink.url !== null ? (
        <PageLink link={props.cardLink}>
          <StyledGridRow color={props.backgroundColor}>
            <StyledGridCol l={6} m={2} s={1} left={!!props.left}>
              <StyledGatsbyImage
                image={props.image}
                alt={props.alt ?? 'image'}
                objectFit="cover"
                style={{
                  width: '100%',
                  margin: '0',
                }}
              ></StyledGatsbyImage>
            </StyledGridCol>
            <ContentCol l={10} m={6} s={3}>
              <TextContentBox>
                <div>
                  {props.iconType ? (
                    <StyledIcon
                      type={props.iconType}
                      color={props.iconColor ? props.iconColor : colors.grey}
                      width={62}
                      height={62}
                      size={0}
                    ></StyledIcon>
                  ) : (
                    <div style={{ height: '15px' }}></div>
                  )}
                  <StyledTitle
                    color={props.titleColor}
                    field={props.title}
                  ></StyledTitle>
                  <Description color={props.descriptionColor}>
                    <RichText field={props.description}></RichText>
                  </Description>
                  <ArrowIcon
                    type="ArrowRight"
                    width={120}
                    height={48}
                    color={props.arrowColor}
                    size={0}
                  ></ArrowIcon>
                </div>
              </TextContentBox>
            </ContentCol>
          </StyledGridRow>
        </PageLink>
      ) : (
        <StyledGridRow color={props.backgroundColor}>
          <StyledGridCol l={6} m={2} s={1} left={!!props.left}>
            <StyledGatsbyImage
              image={props.image}
              alt={props.alt ?? 'image'}
              objectFit="cover"
              style={{
                width: '100%',
                margin: '0',
              }}
            ></StyledGatsbyImage>
          </StyledGridCol>
          <ContentCol l={10} m={6} s={3}>
            <TextContentBox>
              <div>
                {props.iconType ? (
                  <StyledIcon
                    type={props.iconType}
                    color={props.iconColor ? props.iconColor : colors.grey}
                    width={62}
                    height={62}
                    size={0}
                  ></StyledIcon>
                ) : (
                  <div style={{ height: '15px' }}></div>
                )}
                <StyledTitle
                  color={props.titleColor}
                  field={props.title}
                ></StyledTitle>
                <Description color={props.descriptionColor}>
                  <RichText field={props.description}></RichText>
                </Description>
              </div>
            </TextContentBox>
          </ContentCol>
        </StyledGridRow>
      )}
    </Wrapper>
  )
}

const ArrowIcon = styled(Icon)``

const Wrapper = styled.div`
  height: 100%;
  & img,
  ${ArrowIcon} {
    transition: all 0.2s ease-in-out !important;
  }
  &:hover {
    & img,
    ${ArrowIcon} {
      transform: scale(1.1);
    }
  }
`

const StyledGridCol = styled(GridCol)<{ left: boolean }>`
  order: ${({ left }) => (left ? 1 : 3)};
`

const ContentCol = styled(GridCol)`
  order: 2;
  padding-bottom: 20px;
`

const StyledIcon = styled(Icon)`
  svg {
    margin-bottom: 4px;
    margin-top: 16px;
    width: 36px !important;
    height: 36px !important;
  }
  ${mqFrom.S`
    margin-bottom: 26px;
    svg {
      width: 62px !important;
      height: 62px !important;
    }
  `}
`

const Description = styled.div<{ color: string }>`
  ${applyTextStyle('normal')}
  color: ${({ color }) => color};
`

const StyledGridRow = styled(GridRow)<{ color: string }>`
  background-color: ${({ color }) => color};
  height: 100%;
`

const Title = styled(PageTitle)<{ color: string }>`
  color: ${({ color }) => color};
  ${applyTextStyle('titleSubtle')};
  text-align: left;
`

const StyledTitle = styled(Title)`
  margin-bottom: 24px;
`

const TextContentBox = styled.div`
  padding: 10px 10px 10px 15px;
  height: 100%;
  display: flex;
  align-items: center;
  ${mqFrom.S`
    padding: 40px 20px 20px 40px;
  `}
`

const StyledGatsbyImage = styled(GatsbyImage)`
  ${mqFrom.S`
    height: 100%;
  `}
`

export default ArrowCard
