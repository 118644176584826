import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconArrowCircle = (
  props: IProps & { secondaryColor: string; className?: string }
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 80 80"
    className={props.className}
  >
    <g id="Group_5666" data-name="Group 5666" transform="translate(-180 -4756)">
      <g
        id="Path_887"
        data-name="Path 887"
        transform="translate(180 4756)"
        fill={props.color}
      >
        <path
          d="M 40 79 C 34.73458862304688 79 29.62722206115723 77.96898651123047 24.81974411010742 75.93560028076172 C 20.17577743530273 73.97136688232422 16.00485610961914 71.1591796875 12.42283344268799 67.57716369628906 C 8.840822219848633 63.99514389038086 6.028633117675781 59.82422256469727 4.064400196075439 55.18025588989258 C 2.03101110458374 50.37277603149414 1 45.26541137695312 1 40 C 1 34.73458862304688 2.03101110458374 29.62722206115723 4.064400196075439 24.81974411010742 C 6.028633117675781 20.17577743530273 8.840822219848633 16.00485610961914 12.42283344268799 12.42283344268799 C 16.00485610961914 8.840822219848633 20.17577743530273 6.028633117675781 24.81974411010742 4.064400196075439 C 29.62722206115723 2.03101110458374 34.73458862304688 1 40 1 C 45.26541137695312 1 50.37277603149414 2.03101110458374 55.18025588989258 4.064400196075439 C 59.82422256469727 6.028633117675781 63.99514389038086 8.840822219848633 67.57716369628906 12.42283344268799 C 71.1591796875 16.00485610961914 73.97136688232422 20.17577743530273 75.93560028076172 24.81974411010742 C 77.96898651123047 29.62722206115723 79 34.73458862304688 79 40 C 79 45.26541137695312 77.96898651123047 50.37277603149414 75.93560028076172 55.18025588989258 C 73.97136688232422 59.82422256469727 71.1591796875 63.99514389038086 67.57716369628906 67.57716369628906 C 63.99514389038086 71.1591796875 59.82422256469727 73.97136688232422 55.18025588989258 75.93560028076172 C 50.37277603149414 77.96898651123047 45.26541137695312 79 40 79 Z"
          stroke="none"
        />
        <path
          d="M 40 2 C 34.8691520690918 2 29.89284515380859 3.004432678222656 25.20929336547852 4.98541259765625 C 20.68452835083008 6.899223327636719 16.62044525146484 9.639450073242188 13.12994384765625 13.12994384765625 C 9.639450073242188 16.62044525146484 6.899223327636719 20.68452835083008 4.98541259765625 25.20929336547852 C 3.004432678222656 29.89284515380859 2 34.8691520690918 2 40 C 2 45.1308479309082 3.004432678222656 50.10715484619141 4.98541259765625 54.79070663452148 C 6.899223327636719 59.31546783447266 9.639450073242188 63.37955474853516 13.12994384765625 66.87005615234375 C 16.62044525146484 70.36054992675781 20.68452835083008 73.10077667236328 25.20929336547852 75.01458740234375 C 29.89284515380859 76.99556732177734 34.8691520690918 78 40 78 C 45.1308479309082 78 50.10715484619141 76.99556732177734 54.79070663452148 75.01458740234375 C 59.31546783447266 73.10077667236328 63.37955474853516 70.36054992675781 66.87005615234375 66.87005615234375 C 70.36054992675781 63.37955474853516 73.10077667236328 59.31546783447266 75.01458740234375 54.79070663452148 C 76.99556732177734 50.10715484619141 78 45.1308479309082 78 40 C 78 34.8691520690918 76.99556732177734 29.89284515380859 75.01458740234375 25.20929336547852 C 73.10077667236328 20.68452835083008 70.36054992675781 16.62044525146484 66.87005615234375 13.12994384765625 C 63.37955474853516 9.639450073242188 59.31546783447266 6.899223327636719 54.79070663452148 4.98541259765625 C 50.10715484619141 3.004432678222656 45.1308479309082 2 40 2 M 40 0 C 62.09138870239258 0 80 17.90861129760742 80 40 C 80 62.09138870239258 62.09138870239258 80 40 80 C 17.90861129760742 80 0 62.09138870239258 0 40 C 0 17.90861129760742 17.90861129760742 0 40 0 Z"
          stroke="none"
          fill={props.secondaryColor}
        />
      </g>
      <g
        id="Group_5725"
        data-name="Group 5725"
        transform="translate(5.364 -94.819)"
      >
        <g
          id="Group_5722"
          data-name="Group 5722"
          transform="translate(199 4878)"
        >
          <path
            id="Path_761"
            data-name="Path 761"
            d="M7.5,18H34.772"
            transform="translate(-7.5 -5.182)"
            fill="none"
            stroke={props.secondaryColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Path_762"
            data-name="Path 762"
            d="M18,7.5l9.412,12.819L18,33.138"
            transform="translate(-0.14 -7.5)"
            fill="none"
            stroke={props.secondaryColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default IconArrowCircle
