// Global stylesheet
import { createGlobalStyle } from 'styled-components'

// Styling

const GlobalStyle = createGlobalStyle`

`

export default GlobalStyle

// footer {
//   ${({ theme }) =>
//     css`
//       background: ${theme.footerBackground};
//       ${mqFrom.M`
//         background: ${theme.footerBackgroundDesktop};
//       `}
//     `}
// }
