import { PrismicRichText } from '@prismicio/react'
import React from 'react'
import styled from 'styled-components'

interface IProps {
  field: any
  color?: string
}

const RichText: React.FC<IProps> = ({ field, color }) => {
  return (
    <StyledRichText color={color}>
      <PrismicRichText field={field} />
    </StyledRichText>
  )
}

const StyledRichText = styled.div<{ color?: string }>`
  // strong {
  //   font-size: 24px;
  //   font-family: Nexa, Helvetica, Arial, sans-serif;
  // }
  h2 {
    font-size: 42px;
  }
  h2 {
    font-size: 36px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 24px;
  }
  h6 {
    font-size: 24px;
    line-height: 32px;
  }
  a {
    ${(props) =>
      props.color
        ? `
        color: ${props.color}; 
        border-bottom: 2px solid ${props.color};
        `
        : ''}
  }
`

export default RichText
