import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import CornerStoneGrid from '../CornerStoneGrid'
import TitleContent from '../TitleContent'
import { IPrismicLink } from '../PageLink'

interface ISlice {
  items: {
    card_title: {
      richText: any
    }
    description: {
      richText: string
    }
    description_color: string
    background_color1: string
    title_color: string
    image: any
    bottom_link_text: string
    bottom_link: IPrismicLink
  }[]
  primary: {
    title: {
      richText: string
    }
  }
}

const RectangularCardsGridSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const titles = slice.items.map((item) => item.card_title.richText)
  const descriptions = slice.items.map((item) => item.description.richText)
  const images = slice.items.map((item) => item.image)
  const titleColors = slice.items.map((item) => item.title_color)
  const backgroundColors = slice.items.map((item) => item.background_color1)
  const descriptionColors = slice.items.map((item) => item.description_color)
  const links = slice.items.map((item) => item.bottom_link)
  const linkTexts = slice.items.map((item) => item.bottom_link_text)

  return (
    <TitleContent title={slice.primary.title.richText}>
      <CornerStoneGrid
        titles={titles}
        descriptions={descriptions}
        titleColors={titleColors}
        backgroundColors={backgroundColors}
        images={images}
        descriptionColors={descriptionColors}
        links={links}
        linkTexts={linkTexts}
      ></CornerStoneGrid>
    </TitleContent>
  )
}

export default RectangularCardsGridSlice
