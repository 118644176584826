import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconFooterLogo = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    id="_01_worldofwork_blk"
    data-name="01_worldofwork_blk"
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 354 44.147"
  >
    <path
      id="Path_984"
      data-name="Path 984"
      d="M347.54,976.52H354.8l3.412,11.093,3.23,11.216,7.743-22.309h5.8l7.678,22.43,3.353-11.337L389.3,976.52h7.252l-10.006,30.124H379.3l-2.866-8.594L372.1,985.982l-4.328,12.068-2.863,8.594H357.6Z"
      transform="translate(-347.54 -963.172)"
      fill="#fff"
    />
    <path
      id="Path_985"
      data-name="Path 985"
      d="M532.28,989.746c0-9.023,6.278-15.726,16.09-15.726,9.751,0,16.029,6.7,16.029,15.726s-6.278,15.723-16.029,15.723C538.558,1005.469,532.28,998.766,532.28,989.746Zm16.09,9.507c5.608,0,9.2-4.266,9.2-9.507s-3.594-9.51-9.2-9.51c-5.67,0-9.264,4.266-9.264,9.51S542.7,999.254,548.37,999.254Z"
      transform="translate(-482.804 -961.342)"
      fill="#fff"
    />
    <path
      id="Path_986"
      data-name="Path 986"
      d="M665.9,974.858H672l.062,4.633c2.375-3.658,5.356-5.241,9.14-5.241a11.652,11.652,0,0,1,7.619,2.619l-3.048,5.546a8.491,8.491,0,0,0-5.059-1.644c-4.874,0-7.984,3.6-7.984,9.507v14.7H665.9Z"
      transform="translate(-580.639 -961.51)"
      fill="#fff"
    />
    <path
      id="Path_987"
      data-name="Path 987"
      d="M761.06,929.64h6.827v42.679H761.06Z"
      transform="translate(-650.313 -928.847)"
      fill="#fff"
    />
    <path
      id="Path_988"
      data-name="Path 988"
      d="M800.87,957.252c0-9.51,6.278-15.726,14.81-15.726a14.439,14.439,0,0,1,10.423,3.9V929.64h6.827v42.679h-6.1l-.062-4.017c-2.317,2.924-6.339,4.692-11.093,4.692C807.148,972.975,800.87,966.759,800.87,957.252Zm16.029,9.507c5.426,0,9.2-3.9,9.2-9.507s-3.773-9.51-9.2-9.51-9.2,3.9-9.2,9.51S811.475,966.759,816.9,966.759Z"
      transform="translate(-679.462 -928.847)"
      fill="#fff"
    />
    <path
      id="Path_989"
      data-name="Path 989"
      d="M938.6,989.731c0-9.023,6.267-15.731,16.09-15.731,9.754,0,16.031,6.7,16.031,15.726s-6.278,15.723-16.031,15.723C944.867,1005.455,938.6,998.751,938.6,989.731Zm16.09,9.507c5.608,0,9.2-4.266,9.2-9.507s-3.6-9.51-9.2-9.51c-5.667,0-9.264,4.266-9.264,9.51S949.023,999.239,954.69,999.239Z"
      transform="translate(-780.305 -961.327)"
      fill="#fff"
    />
    <path
      id="Path_990"
      data-name="Path 990"
      d="M1080.528,926.68a12.839,12.839,0,0,1,7.678,2.56l-2.619,4.821a7.864,7.864,0,0,0-4.51-1.524c-2.8,0-4.754,1.706-4.754,5.667v1.829h8.958v5.852h-8.958v24.267H1069.5V945.88h-5.67v-5.852h5.67V938.2C1069.5,930.515,1074.371,926.68,1080.528,926.68Z"
      transform="translate(-871.997 -926.68)"
      fill="#fff"
    />
    <path
      id="Path_991"
      data-name="Path 991"
      d="M1152,976.52h7.252l3.415,11.093,3.23,11.216,7.74-22.309h5.79l7.681,22.43,3.35-11.337,3.292-11.093h7.26L1191,1006.644h-7.252l-2.865-8.594-4.325-12.068-4.328,12.068-2.866,8.594h-7.314Z"
      transform="translate(-936.554 -963.172)"
      fill="#fff"
    />
    <path
      id="Path_992"
      data-name="Path 992"
      d="M1334.83,989.746c0-9.023,6.278-15.726,16.09-15.726,9.751,0,16.029,6.7,16.029,15.726s-6.278,15.723-16.029,15.723C1341.108,1005.469,1334.83,998.766,1334.83,989.746Zm16.09,9.507c5.608,0,9.2-4.266,9.2-9.507s-3.594-9.51-9.2-9.51c-5.67,0-9.264,4.266-9.264,9.51S1345.251,999.254,1350.92,999.254Z"
      transform="translate(-1070.419 -961.342)"
      fill="#fff"
    />
    <path
      id="Path_993"
      data-name="Path 993"
      d="M1470,974.858h6.1l.062,4.633c2.378-3.658,5.356-5.241,9.143-5.241a11.657,11.657,0,0,1,7.619,2.619l-3.048,5.546a8.5,8.5,0,0,0-5.059-1.644c-4.877,0-7.986,3.6-7.986,9.507v14.7H1470Z"
      transform="translate(-1169.389 -961.51)"
      fill="#fff"
    />
    <path
      id="Path_994"
      data-name="Path 994"
      d="M1572.4,959.019v13.3h-6.827V929.64h6.827v25.233l10.788-12.678h8.35l-12.617,14.446,14.446,15.664h-8.9Z"
      transform="translate(-1239.364 -928.847)"
      fill="#fff"
    />
    <path
      id="Path_995"
      data-name="Path 995"
      d="M347.54,976.52H354.8l3.412,11.093,3.23,11.216,7.743-22.309h5.8l7.678,22.43,3.353-11.337L389.3,976.52h7.252l-10.006,30.124H379.3l-2.866-8.594L372.1,985.982l-4.328,12.068-2.863,8.594H357.6Z"
      transform="translate(-347.54 -963.172)"
      fill={props.color}
    />
    <path
      id="Path_996"
      data-name="Path 996"
      d="M532.28,989.746c0-9.023,6.278-15.726,16.09-15.726,9.751,0,16.029,6.7,16.029,15.726s-6.278,15.723-16.029,15.723C538.558,1005.469,532.28,998.766,532.28,989.746Zm16.09,9.507c5.608,0,9.2-4.266,9.2-9.507s-3.594-9.51-9.2-9.51c-5.67,0-9.264,4.266-9.264,9.51S542.7,999.254,548.37,999.254Z"
      transform="translate(-482.804 -961.342)"
      fill={props.color}
    />
    <path
      id="Path_997"
      data-name="Path 997"
      d="M665.9,974.858H672l.062,4.633c2.375-3.658,5.356-5.241,9.14-5.241a11.652,11.652,0,0,1,7.619,2.619l-3.048,5.546a8.491,8.491,0,0,0-5.059-1.644c-4.874,0-7.984,3.6-7.984,9.507v14.7H665.9Z"
      transform="translate(-580.639 -961.51)"
      fill={props.color}
    />
    <path
      id="Path_998"
      data-name="Path 998"
      d="M761.06,929.64h6.827v42.679H761.06Z"
      transform="translate(-650.313 -928.847)"
      fill={props.color}
    />
    <path
      id="Path_999"
      data-name="Path 999"
      d="M800.87,957.252c0-9.51,6.278-15.726,14.81-15.726a14.439,14.439,0,0,1,10.423,3.9V929.64h6.827v42.679h-6.1l-.062-4.017c-2.317,2.924-6.339,4.692-11.093,4.692C807.148,972.975,800.87,966.759,800.87,957.252Zm16.029,9.507c5.426,0,9.2-3.9,9.2-9.507s-3.773-9.51-9.2-9.51-9.2,3.9-9.2,9.51S811.475,966.759,816.9,966.759Z"
      transform="translate(-679.462 -928.847)"
      fill={props.color}
    />
    <path
      id="Path_1000"
      data-name="Path 1000"
      d="M938.6,989.731c0-9.023,6.267-15.731,16.09-15.731,9.754,0,16.031,6.7,16.031,15.726s-6.278,15.723-16.031,15.723C944.867,1005.455,938.6,998.751,938.6,989.731Zm16.09,9.507c5.608,0,9.2-4.266,9.2-9.507s-3.6-9.51-9.2-9.51c-5.667,0-9.264,4.266-9.264,9.51S949.023,999.239,954.69,999.239Z"
      transform="translate(-780.305 -961.327)"
      fill={props.color}
    />
    <path
      id="Path_1001"
      data-name="Path 1001"
      d="M1080.528,926.68a12.839,12.839,0,0,1,7.678,2.56l-2.619,4.821a7.864,7.864,0,0,0-4.51-1.524c-2.8,0-4.754,1.706-4.754,5.667v1.829h8.958v5.852h-8.958v24.267H1069.5V945.88h-5.67v-5.852h5.67V938.2C1069.5,930.515,1074.371,926.68,1080.528,926.68Z"
      transform="translate(-871.997 -926.68)"
      fill={props.color}
    />
    <path
      id="Path_1002"
      data-name="Path 1002"
      d="M1152,976.52h7.252l3.415,11.093,3.23,11.216,7.74-22.309h5.79l7.681,22.43,3.35-11.337,3.292-11.093h7.26L1191,1006.644h-7.252l-2.865-8.594-4.325-12.068-4.328,12.068-2.866,8.594h-7.314Z"
      transform="translate(-936.554 -963.172)"
      fill={props.color}
    />
    <path
      id="Path_1003"
      data-name="Path 1003"
      d="M1334.83,989.746c0-9.023,6.278-15.726,16.09-15.726,9.751,0,16.029,6.7,16.029,15.726s-6.278,15.723-16.029,15.723C1341.108,1005.469,1334.83,998.766,1334.83,989.746Zm16.09,9.507c5.608,0,9.2-4.266,9.2-9.507s-3.594-9.51-9.2-9.51c-5.67,0-9.264,4.266-9.264,9.51S1345.251,999.254,1350.92,999.254Z"
      transform="translate(-1070.419 -961.342)"
      fill={props.color}
    />
    <path
      id="Path_1004"
      data-name="Path 1004"
      d="M1470,974.858h6.1l.062,4.633c2.378-3.658,5.356-5.241,9.143-5.241a11.657,11.657,0,0,1,7.619,2.619l-3.048,5.546a8.5,8.5,0,0,0-5.059-1.644c-4.877,0-7.986,3.6-7.986,9.507v14.7H1470Z"
      transform="translate(-1169.389 -961.51)"
      fill={props.color}
    />
    <path
      id="Path_1005"
      data-name="Path 1005"
      d="M1572.4,959.019v13.3h-6.827V929.64h6.827v25.233l10.788-12.678h8.35l-12.617,14.446,14.446,15.664h-8.9Z"
      transform="translate(-1239.364 -928.847)"
      fill={props.color}
    />
  </svg>
)

export default IconFooterLogo
