import React from 'react'
import Fade from 'react-reveal/Fade'

interface IProps {
  children: React.ReactNode
  animated?: boolean
  className?: string
}

const Slides: React.FC<IProps> = ({ children, animated, className }) => {
  const isAnimated = animated !== undefined ? animated : true
  return (
    <>
      {!isAnimated ? (
        <>{children}</>
      ) : (
        <Fade
          bottom
          duration={800}
          distance={'min(150px, 12vh)'}
          className={className}
        >
          {children}
        </Fade>
      )}
    </>
  )
}

export default Slides
