import React from 'react'
import styled from 'styled-components'
import { SliceComponentProps } from '@prismicio/react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import Rectangles from '../Rectangles'
import AppContainer from '../AppContainer'
import { IPageTheme } from '../../pages/{PrismicPage.url}'

interface ISlice {
  primary: {
    title: {
      text: string
    }
    work_at_wow_card_arrow_label: string
    work_at_wow_card_background_color: string
    work_at_wow_card_description: {
      richText: string
    }
    work_at_wow_card_elements_color: string
    work_at_wow_card_image: any
    work_at_wow_card_page_link: any
    work_at_wow_card_title: {
      text: string
    }
  }
}

const BlogpostSectionSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const context = props.context as IPageTheme

  const staticData = useStaticQuery(graphql`
    query {
      allPrismicPage(
        sort: { fields: first_publication_date, order: DESC }
        filter: { tags: { in: "blog" } }
      ) {
        nodes {
          _previewable
          url
          lang
          first_publication_date
          data {
            blogpost_card_image {
              alt
              gatsbyImageData(aspectRatio: 0.9, placeholder: BLURRED)
            }
            body {
              ... on PrismicPageDataBodyPageHeader {
                id
                slice_label
                slice_type
                primary {
                  title {
                    richText
                  }
                  description {
                    richText
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyBlogOverviewDetails {
                id
                slice_label
                slice_type
                primary {
                  title {
                    richText
                  }
                  description {
                    richText
                    text
                  }
                  custom_publish_date
                }
              }
              ... on PrismicPageDataBodyImage {
                id
                slice_label
                slice_type
                primary {
                  image {
                    alt
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const merge = useMergePrismicPreviewData(staticData)
  const data = merge.data.allPrismicPage.nodes.filter(
    (e: any) => e.lang === context.lang
  )
  const blogposts = data.map((post: any) => {
    const titleSlice = post.data.body.find(
      (slice: any) => slice.slice_type === 'page_header'
    )
    const overviewSlice = post.data.body.find(
      (slice: any) => slice.slice_type === 'blog_overview_details'
    )
    const image = post.data.blogpost_card_image.gatsbyImageData

    let description =
      titleSlice?.primary?.description?.text?.slice(0, 100) + '...' ||
      'Description'
    if (overviewSlice?.primary?.description?.text) {
      if (overviewSlice?.primary?.description?.text.length < 100) {
        description = overviewSlice?.primary?.description?.text
      } else {
        description =
          overviewSlice?.primary?.description?.text.slice(0, 100) + '...'
      }
    }

    return {
      title:
        overviewSlice?.primary?.title?.richText ||
        titleSlice?.primary?.title?.richText ||
        '',
      text: description || 'Description',
      link: {
        url: post?.url,
        link_type: 'Document',
      },
      image: image,
      alt: post.data.blogpost_card_image.alt,
      firstPublicationDate:
        overviewSlice?.primary?.custom_publish_date ??
        post.first_publication_date,
    }
  })

  return (
    <Wrapper>
      <AppContainer withMobilePadding={true}>
        <Rectangles
          cards={blogposts}
          workAtWoWCardInfo={{
            title: slice.primary.work_at_wow_card_title.text,
            description: slice.primary.work_at_wow_card_description.richText,
            imageSrc: slice.primary.work_at_wow_card_image.gatsbyImageData,
            elementsColor: slice.primary.work_at_wow_card_elements_color,
            backgroundColor: slice.primary.work_at_wow_card_background_color,
            arrowText: slice.primary.work_at_wow_card_arrow_label,
            pageLink: slice.primary.work_at_wow_card_page_link,
          }}
          lang={context.lang}
        ></Rectangles>
      </AppContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

export default BlogpostSectionSlice
