import React from 'react'
import styled from 'styled-components'
import { SliceComponentProps } from '@prismicio/react'
import { IPrismicLink } from '../PageLink'
import Breadcrumb from '../Breadcrumb'
import { mqFrom } from '../../styles/responsive'
import { IPageTheme } from '../../pages/{PrismicPage.url}'

interface ISlice {
  primary: {
    breadcrumb_level_one_label: string
    breadcrumb_level_one_link: IPrismicLink
    breadcrumb_level_two_label: string
    breadcrumb_level_two_link: IPrismicLink
    breadcrumb_level_three_label: string
    breadcrumb_level_three_link: IPrismicLink
    breadcrumb_level_four_label: string
    breadcrumb_level_four_link: IPrismicLink
  }
}

const BreadcrumbSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const context = props.context as IPageTheme

  return (
    <Wrapper>
      <Breadcrumb
        breadcrumbLevelOneLabel={slice.primary.breadcrumb_level_one_label}
        breadcrumbLevelTwoLabel={slice.primary.breadcrumb_level_two_label}
        breadcrumbLevelThreeLabel={slice.primary.breadcrumb_level_three_label}
        breadcrumbLevelFourLabel={slice.primary.breadcrumb_level_four_label}
        breadcrumbLevelOneLink={slice.primary.breadcrumb_level_one_link}
        breadcrumbLevelTwoLink={slice.primary.breadcrumb_level_two_link}
        breadcrumbLevelThreeLink={slice.primary.breadcrumb_level_three_link}
        theme={context}
      ></Breadcrumb>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 100px;
  width: 100%;
  display: none;
  ${mqFrom.S`
    margin-top: 200px;
    margin-bottom: 80px;
    display: block;
  `}
`

export default BreadcrumbSlice
