import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import ContactForm from '../contact-form'

interface ISlice {
  primary: {
    bottom_message: {
      richText: string
    }
    button_label: string
    email_label: string
    form_title: {
      text: string
    }
    image: any
    name_label: string
    notes_label: string
    phone_label: string
  }
}

const ContactFormSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  return (
    <ContactForm
      bottomMessage={slice.primary.bottom_message.richText}
      formTitle={slice.primary.form_title.text}
      image={slice.primary.image}
      nameLabel={slice.primary.name_label}
      notesLabel={slice.primary.notes_label}
      phoneLabel={slice.primary.phone_label}
      buttonLabel={slice.primary.button_label}
      emailLabel={slice.primary.email_label}
    ></ContactForm>
  )
}

export default ContactFormSlice
