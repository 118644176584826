import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { useEffect } from 'react'
import { applyTextStyle } from '../../styles/typography'
import { SliceComponentProps } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import RichText from '../RichText'
import AppContainer from '../AppContainer'
import { mqFrom } from '../../styles/responsive'
import Slides from '../Animations'
import VideoCover from '../../images/video-cover.jpg'
import PageTitle from '../PageTitle'
import IconArrowCircle from '../icon/IconArrowCircle'

interface ISlice {
  primary: {
    title1: {
      richText: string
    }
    background_color: string
    text_color: string
  }
  items: {
    image: any
    title1: {
      richText: any
    }
    content: {
      richText: any
    }
    video_link?: {
      url?: string
    }
  }[]
}

const GreenSlider = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const scrollableContainerRef = useRef<HTMLDivElement>(null)

  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!videoRef.current) {
      return
    }
    const options = {
      rootMargin: '0px',
      threshold: [0.25, 0.75],
    }

    const handlePlay = (entries: any) => {
      entries.forEach((entry: any) => {
        if (videoRef?.current) {
          try {
            if (entry.isIntersecting) videoRef.current.play()
            else videoRef.current.pause()
          } catch (e) {
            console.error(e)
          }
        }
      })
    }

    const observer = new IntersectionObserver(handlePlay, options)

    observer.observe(videoRef.current)
  }, [videoRef.current])

  const [rangeValue, setRangeValue] = useState(0)
  const onItemScroll = () => {
    const scrollAmount = scrollableContainerRef.current?.scrollLeft || 1
    const scrollMax =
      (scrollableContainerRef.current?.scrollWidth || 1) -
      (scrollableContainerRef.current?.clientWidth || 0)

    const scrollPercentage = (scrollAmount / Math.max(scrollMax, 1)) * 10000
    setRangeValue(scrollPercentage)
  }

  const onSliderChange = (value: number) => {
    const percent = value / 100 / 100
    const scrollMax =
      (scrollableContainerRef.current?.scrollWidth || 1) -
      (scrollableContainerRef.current?.clientWidth || 0)

    setRangeValue(value)
    scrollableContainerRef.current?.scrollTo({
      left: scrollMax * percent,
    })
  }

  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.addEventListener('scroll', onItemScroll)
      return () => {
        scrollableContainerRef.current?.removeEventListener(
          'scroll',
          onItemScroll
        )
      }
    }
  }, [scrollableContainerRef.current])

  return (
    <Slides>
      <Wrapper>
        <AppContainer withMobilePadding={true}>
          <WayWeWorkTitle
            color={slice.primary.background_color || colors.forrestGreen}
            field={slice.primary.title1.richText}
          ></WayWeWorkTitle>
        </AppContainer>
        <GreenBox
          background={slice.primary.background_color || colors.forrestGreen}
          color={slice.primary.text_color || colors.salmon}
        >
          <Content ref={scrollableContainerRef}>
            <ScrollContent>
              {slice.items.map((item, i) => (
                <Container key={'item-green-slider-' + i}>
                  <StyledGatsbyImage
                    loading="lazy"
                    image={item.image.gatsbyImageData}
                    alt={item.image.alt ?? 'Slider image item'}
                    style={{
                      height: '100%',
                      width: 'auto',
                    }}
                    imgStyle={{
                      height: '100%',
                      width: 'auto',
                    }}
                  ></StyledGatsbyImage>
                  {item.video_link?.url && (
                    <>
                      <StyledVideo
                        ref={videoRef}
                        muted={true}
                        src={item.video_link?.url}
                        poster={VideoCover}
                        loop
                      ></StyledVideo>
                    </>
                  )}
                  <ContentAlignment top={i % 2 == 0}>
                    <Title field={item.title1.richText}></Title>
                    <StyledRichText>
                      <RichText field={item.content.richText}></RichText>
                    </StyledRichText>
                  </ContentAlignment>
                </Container>
              ))}
            </ScrollContent>
          </Content>
          <RangeBox>
            <RangeLeftLine
              color={slice.primary.text_color || colors.salmon}
              width={rangeValue / 100}
            ></RangeLeftLine>
            <RangeRightLine
              color={slice.primary.text_color || colors.salmon}
              width={100 - rangeValue / 100}
            ></RangeRightLine>
            <StyledIconSlider
              left={rangeValue}
              width={40}
              height={40}
              secondaryColor={slice.primary.text_color || colors.salmon}
              color={slice.primary.background_color || colors.forrestGreen}
            ></StyledIconSlider>
            <RangeInput
              type="range"
              min="1"
              max="10000"
              value={rangeValue}
              onChange={(e) => {
                onSliderChange(+e.target.value)
              }}
            />
          </RangeBox>
        </GreenBox>
      </Wrapper>
    </Slides>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const RangeInput = styled.input`
  width: 100%;
  -webkit-appearance: none;
  width: 100%;
  height: 0;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  left: 0;
  position: absolute;
  background: transparent;
  z-index: 4;
  ${mqFrom.S`
    top: 38px;
  `}

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
    border-radius: 100%;
    cursor: pointer;
    border: 0;
    background: transparent;
    ${mqFrom.S`
      width: 80px;
      height: 80px;
      background-size: 80px 80px;
    `}
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
    border-radius: 100%;
    cursor: pointer;
    border: 0;
    background: transparent;
    ${mqFrom.S`
      width: 80px;
      height: 80px;
      background-size: 80px 80px;
    `}
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 2900px;
  margin: 0 auto;
  max-height: 1500px;
  // overflow-y: hidden;
  overflow: auto;
  margin-top: -60px;
  height: 45vh;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${mqFrom.S`
    height: 60vh;
  `}
`

const ScrollContent = styled.div`
  height: 100%;
  flex-wrap: nowrap;
  // overflow: hidden;
  display: flex;
`

const RangeBox = styled.div`
  width: 100%;
  max-width: 2900px;
  position: relative;
  margin: 40px auto 80px;
  ${mqFrom.S`
    margin: 80px auto 210px;
  `}
`

const RangeLeftLine = styled.div<{ width: number; color: string }>`
  width: ${({ width }) => width}%;
  height: 0;
  border: 2px solid ${({ color }) => color};
  left: 0;
  position: absolute;
  ${mqFrom.S`
    top: 38px;
  `}
`

const RangeRightLine = styled.div<{ width: number; color: string }>`
  width: ${({ width }) => width}%;
  height: 0;
  border: 2px dashed ${({ color }) => color};
  right: 0;
  position: absolute;
  ${mqFrom.S`
    top: 38px;
  `}
`

const GreenBox = styled.div<{ background: string; color: string }>`
  width: 100%;
  padding: 0 6.25vw;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 120px;
`

const WayWeWorkTitle = styled(PageTitle)<{ color: string }>`
  ${applyTextStyle('titleLarge')}
  margin-top: 160px;
  margin-bottom: 40px;
  text-align: left;
  ${mqFrom.S`
    color: ${({ color }: any) => color};
    text-align: center;
  `}
`

const Container = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-shrink: 0;
  margin-right: 20px;
  ${mqFrom.S`
    margin-right: 80px;
  `}
`

const StyledGatsbyImage = styled(GatsbyImage)`
  margin-right: 40px;
  > div {
    width: auto;
    height: 100%;
  }
  img {
    width: auto !important;
    height: 100% !important;
  }
  ${mqFrom.S`
    margin-right: 160px;
  `}
`

const StyledRichText = styled.div`
  ${applyTextStyle('large')};
  max-width: min(600px, 80vw);
`

const Title = styled(PageTitle)`
  ${applyTextStyle('titleNormal')};
  margin-bottom: 24px;
`

const ContentAlignment = styled.div<{ top: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ top }) => (top ? 'flex-end' : 'flex-start')};
  padding-top: ${({ top }) => (top ? '0' : '100px')};
`

const StyledVideo = styled.video`
  width: 62.5vw;
  height: 100%;
  width: auto;
  margin-right: 40px;
  max-width: 80vw;
  object-fit: cover;
  ${mqFrom.S`
    margin-right: 80px;
  `}
`

const StyledIconSlider = styled(IconArrowCircle)<{ left: number }>`
  z-index: 2;
  position: absolute;
  top: -20px;
  left: ${({ left }) => `calc(${left / 100}% - ${(left / 10000) * 40}px)`};
  ${mqFrom.S`
    top: 0;
    left: ${({ left }) => `calc(${left / 100}% - ${(left / 10000) * 80}px)`};
    & {
      width: 80px !important;
      height: 80px !important;
    }
  `}
`
export default GreenSlider
