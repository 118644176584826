import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import ArrowSlider from '../ArrowSlider'

interface ISlice {
  items: {
    title: {
      richText: any
    }
    description: {
      text: string
    }
    image: any
  }[]
  primary: {
    title: {
      richText: any
    }
  }
}

const ImagesSlider = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const cards = slice.items.map((item) => ({
    title: item.title.richText,
    text: item.description.text,
    image: item.image.gatsbyImageData,
    alt: item.image.alt ?? 'Image item',
  }))
  return (
    <ArrowSlider
      cards={cards}
      title={slice.primary.title.richText}
    ></ArrowSlider>
  )
}

export default ImagesSlider
