import { SliceComponentProps } from '@prismicio/react'
import React from 'react'

interface ISlice {
  primary: {
    title: {
      richText: any
    }
    description: {
      richText: any
      text: string
    }
    custom_publish_date: string
  }
}

const BlogOverviewDetailsSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  if (!slice) {
    return <></>
  }
  return <></>
}

export default BlogOverviewDetailsSlice
