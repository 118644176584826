import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../../styles/colors'
import { mqFrom } from '../../styles/responsive'
import { applyTextStyle } from '../../styles/typography'
import { GridCol } from '../grid'
import GridRow from '../grid/GridRow'
import RichText from '../RichText'

export interface IProps {
  image: any
  backgroundColor?: string
  title?: any
  left?: boolean
  description: string
}

const StoryCard: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledGridRow color={props.backgroundColor || ''}>
      <StyledGridCol l={8} m={4} s={4} left={!!props.left}>
        <GatsbyImage
          image={props.image.gatsbyImageData}
          alt={props.image.alt ?? 'image'}
          objectFit="cover"
          style={{
            width: '100%',
            height: '100%',
            margin: '0',
          }}
        ></GatsbyImage>
      </StyledGridCol>
      <ContentGridCol l={8} m={4} s={4}>
        <TextContentBox>
          <div>
            <Title color={colors.grey} field={props.title}></Title>
            <Description>
              <RichText field={props.description} />
            </Description>
          </div>
        </TextContentBox>
      </ContentGridCol>
    </StyledGridRow>
  )
}

const Description = styled.div`
  ${applyTextStyle('normal')}
`

const StyledGridRow = styled(GridRow)<{ color: string }>`
  background-color: ${({ color }) => color};
`

const Title = styled(RichText)<{ color: string }>`
  ${applyTextStyle('titleNormalRegular')};
  color: ${({ color }) => color};
  text-align: left;
  padding-bottom: 36px;
`

const TextContentBox = styled.div`
  padding: 40px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  ${mqFrom.S`
    padding: 40px 80px;
  `}
`

const StyledGridCol = styled(GridCol)<{ left: boolean }>`
  order: 1;
  ${({ left }) =>
    css`
      ${!left &&
      mqFrom.S`
          order: 3;
        `}
    `}
`

const ContentGridCol = styled(GridCol)`
  order: 2;
`
export default StoryCard
