import { SliceComponentProps } from '@prismicio/react'
import React from 'react'
import styled from 'styled-components'
import { IPageTheme } from '../../pages/{PrismicPage.url}'
import { mqFrom } from '../../styles/responsive'
import PageHeader from '../PageHeader'
import { IPrismicLink } from '../PageLink'

interface ISlice {
  primary: {
    title: {
      richText: string
    }
    header_image: any
    description: {
      richText: string
    }
    description_link: IPrismicLink
    description_link_text: string
  }
}

const PageHeaderSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const context = props.context as IPageTheme

  const breadcrumbSlice = props.slices.find(
    (x: any) => x.slice_type === 'breadcrumb'
  )
  const hasBreadcrumb = breadcrumbSlice ? true : false

  return (
    <Wrapper hasBreadcrumb={hasBreadcrumb}>
      <PageHeader
        titleColor={context.headersColor}
        title={slice.primary.title.richText}
        description={slice.primary.description.richText}
        image={slice.primary.header_image}
        descriptionLink={slice.primary.description_link}
        descriptionLinkText={slice.primary.description_link_text}
      ></PageHeader>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ hasBreadcrumb?: boolean }>`
  margin-top: 130px;
  ${mqFrom.S`
    margin-top: ${(props) => (props.hasBreadcrumb ? '80px' : '240px')};
    margin-bottom: 80px;
  `}
`

export default PageHeaderSlice
