import React from 'react'
import styled from 'styled-components'
import { SliceComponentProps } from '@prismicio/react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import Rectangles from '../Rectangles'
import AppContainer from '../AppContainer'
import { IPageTheme } from '../../pages/{PrismicPage.url}'

interface ISlice {
  primary: {
    work_at_wow_card_arrow_label: string
    work_at_wow_card_background_color: string
    work_at_wow_card_elements_color: string
    work_at_wow_card_page_link: any
    title: {
      text: string
    }
    work_at_wow_card_description: {
      richText: string
    }
    work_at_wow_card_image: any
    work_at_wow_card_title: {
      text: string
    }
  }
}

const CasesGridSlice = (props: SliceComponentProps) => {
  const slice = props.slice as unknown as ISlice
  const context = props.context as IPageTheme

  const staticData = useStaticQuery(graphql`
    query {
      allPrismicCas(sort: { fields: first_publication_date, order: DESC }) {
        nodes {
          _previewable
          lang
          first_publication_date
          alternate_languages {
            id
            lang
            raw
            type
            uid
          }
          dataRaw
          id
          lang
          url
          type
          tags
          data {
            cover_image {
              gatsbyImageData(placeholder: BLURRED)
              alt
            }
            description {
              text
              richText
            }
            page_link {
              url
              type
              link_type
              id
            }
            title {
              richText
            }
          }
        }
      }
    }
  `)
  const merge = useMergePrismicPreviewData(staticData)
  const data = merge.data.allPrismicCas.nodes.filter(
    (e: any) => e.lang === context.lang
  )

  const cases = data.map((prismicCase) => {
    const image = prismicCase?.data?.cover_image?.gatsbyImageData
    return {
      title: prismicCase?.data?.title?.richText || '',
      text: prismicCase?.data?.description?.text,
      link: prismicCase?.data?.page_link,
      image: image,
      alt: prismicCase?.data?.cover_image?.alt,
      firstPublicationDate: prismicCase?.first_publication_date,
    }
  })

  return (
    <Wrapper>
      <AppContainer withMobilePadding={true}>
        <Rectangles
          cards={cases}
          workAtWoWCardInfo={{
            title: slice.primary.work_at_wow_card_title.text,
            description: slice.primary.work_at_wow_card_description.richText,
            imageSrc: slice.primary.work_at_wow_card_image.gatsbyImageData,
            elementsColor: slice.primary.work_at_wow_card_elements_color,
            backgroundColor: slice.primary.work_at_wow_card_background_color,
            arrowText: slice.primary.work_at_wow_card_arrow_label,
            pageLink: slice.primary.work_at_wow_card_page_link,
          }}
          lang={context.lang}
        ></Rectangles>
      </AppContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

export default CasesGridSlice
