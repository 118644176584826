import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLoadingArrow = (props: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 75 69"
  >
    <path
      id="_02_impelmentation_blk"
      data-name="02_impelmentation_blk"
      d="M551.016,539.438a34.076,34.076,0,0,0-23.8-10.578h-.058a2.15,2.15,0,0,0-2.142,2.154v9.095a2.016,2.016,0,0,0,1.96,2.027c10.721.35,20.073,9.744,20.422,20.512a21.211,21.211,0,0,1-21.124,21.935c-.233,0-.469,0-.7-.012-10.229-.334-19.247-8.947-20.324-19.106h4.337a2.156,2.156,0,0,0,1.769-3.368l-10.907-16.013a2.14,2.14,0,0,0-3.539,0L486,562.095a2.154,2.154,0,0,0,1.769,3.369h4.213a34.466,34.466,0,0,0,34.288,32.4h.479a34.528,34.528,0,0,0,24.27-58.422Z"
      transform="translate(-485.624 -528.86)"
      fill={props.color}
    />
  </svg>
)

export default IconLoadingArrow
