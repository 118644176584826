import React from 'react'
import styled from 'styled-components'
import colors from '../styles/colors'
import { applyTextStyle } from '../styles/typography'
import AppContainer from './AppContainer'
import Slides from './Animations'
import PageTitle from './PageTitle'

interface IProps {
  className?: string
  title: string
  animated?: boolean
  children: React.ReactNode
}
const TitleContent: React.FC<IProps> = ({
  className,
  children,
  title,
  animated,
}) => (
  <Slides>
    <Wrapper className={className}>
      <AppContainer withMobilePadding={true} animated={animated}>
        <StyledPageTitle field={title}></StyledPageTitle>
      </AppContainer>
      {children}
    </Wrapper>
  </Slides>
)

const Wrapper = styled.div`
  margin-top: 160px;
`

const StyledPageTitle = styled(PageTitle)`
  ${applyTextStyle('titleLarge')};
  color: ${colors.grey};
  margin-bottom: 40px;
`

export default TitleContent
