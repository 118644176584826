import { Color } from '../common/models/colors'

/**
 * Colors
 */
export default {
  white: '#FFFFFF',
  black: '#000000',
  sand: '#F8F7F3',
  forrestGreen: '#455C20',
  salmon: '#FFCAA3',
  matteRed: '#A93E18',
  brightRed: '#FF4317',
  bordeauxRed: '#762324',
  turqoise: '#00B9A5',
  grey: '#3F3735',
  freshGreen: '#76B007',
  yellow: '#FFC50D',
  pink: '#FFB1C5',
  brightBlue: '#0076DA',
  lightBlue: '#8CE0E8',
  mattePurple: '#6F575A',
  brightPink: '#FF3A60',
  brown: '#422114',
}

/**
 * Some components allow color customization. Since that's
 * optional, use this as a default fallback color in case
 * it's unset
 */
export const defaultColor: Color = 'white'

export const defaultTextColor: Color = 'black'
