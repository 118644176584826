import React from 'react'
import StoryCard from './cards/StoryCard'
import AppContainer from './AppContainer'
import { IProps as StoryCardInfo } from './cards/StoryCard'

interface IProps {
  className?: string
  cardsInfo: StoryCardInfo[]
}

const OurStory: React.FC<IProps> = (props: IProps) => {
  return (
    <AppContainer className={props.className}>
      {props.cardsInfo.map((cardInfo, index) => (
        <StoryCard
          key={index}
          image={cardInfo.image}
          title={cardInfo.title}
          backgroundColor={cardInfo.backgroundColor}
          left={index % 2 == 0 ? true : false}
          description={cardInfo.description}
        ></StoryCard>
      ))}
    </AppContainer>
  )
}

export default OurStory
