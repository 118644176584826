import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconInstagram = (props: IProps) => (
  <svg
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 39.009 39"
  >
    <path
      id="Icon_awesome-instagram"
      data-name="Icon awesome-instagram"
      d="M19.5,11.739a10,10,0,1,0,10,10A9.983,9.983,0,0,0,19.5,11.739Zm0,16.5a6.5,6.5,0,1,1,6.5-6.5,6.513,6.513,0,0,1-6.5,6.5ZM32.244,11.33A2.332,2.332,0,1,1,29.912,9,2.327,2.327,0,0,1,32.244,11.33ZM38.867,13.7c-.148-3.124-.862-5.892-3.15-8.172s-5.047-2.994-8.172-3.15c-3.22-.183-12.871-.183-16.091,0-3.115.148-5.883.862-8.172,3.142S.288,10.564.132,13.688c-.183,3.22-.183,12.871,0,16.091C.28,32.9.993,35.67,3.282,37.95s5.047,2.994,8.172,3.15c3.22.183,12.871.183,16.091,0,3.124-.148,5.892-.862,8.172-3.15s2.994-5.047,3.15-8.172c.183-3.22.183-12.862,0-16.082Zm-4.16,19.537A6.581,6.581,0,0,1,31,36.941c-2.567,1.018-8.659.783-11.5.783s-8.937.226-11.5-.783A6.581,6.581,0,0,1,4.3,33.234c-1.018-2.567-.783-8.659-.783-11.5s-.226-8.937.783-11.5A6.581,6.581,0,0,1,8.008,6.535c2.567-1.018,8.659-.783,11.5-.783s8.937-.226,11.5.783a6.581,6.581,0,0,1,3.707,3.707c1.018,2.567.783,8.659.783,11.5S35.725,30.675,34.707,33.234Z"
      transform="translate(0.005 -2.238)"
      fill={props.color}
    />
  </svg>
)

export default IconInstagram
