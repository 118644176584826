import React from 'react'
import { IIconProps as IProps } from './IIconProps'

const IconLoading = (props: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: `${props.width}px`,
      height: `${props.height}px`,
    }}
    viewBox="0 0 69 69"
  >
    <g id="_04_solutions_blk" data-name="04_solutions_blk" opacity="0.999">
      <path
        id="Path_122"
        data-name="Path 122"
        d="M926.648,1184.922a4.434,4.434,0,0,1-4.428-4.428v-11.666a4.428,4.428,0,0,1,8.856,0v11.666A4.434,4.434,0,0,1,926.648,1184.922Z"
        transform="translate(-892.15 -1115.922)"
        fill={props.color}
      />
      <path
        id="Path_123"
        data-name="Path 123"
        d="M926.648,577.888a4.434,4.434,0,0,1-4.428-4.428V562.178a4.428,4.428,0,1,1,8.856,0v11.281A4.434,4.434,0,0,1,926.648,577.888Z"
        transform="translate(-892.15 -557.75)"
        fill={props.color}
      />
      <path
        id="Path_124"
        data-name="Path 124"
        d="M660.267,1114.157a4.428,4.428,0,0,1-3.131-7.559l8.252-8.249a4.429,4.429,0,0,1,6.262,6.263l-8.249,8.251A4.4,4.4,0,0,1,660.267,1114.157Z"
        transform="translate(-647.069 -1053.926)"
        fill={props.color}
      />
      <path
        id="Path_125"
        data-name="Path 125"
        d="M1092.707,684.923a4.428,4.428,0,0,1-3.131-7.559l7.977-7.977a4.428,4.428,0,1,1,6.263,6.262l-7.981,7.977a4.4,4.4,0,0,1-3.127,1.3Z"
        transform="translate(-1044.88 -659.321)"
        fill={props.color}
      />
      <path
        id="Path_126"
        data-name="Path 126"
        d="M668.554,685.187a4.4,4.4,0,0,1-3.128-1.3l-8.249-8.249a4.428,4.428,0,0,1,6.262-6.262l8.249,8.249a4.428,4.428,0,0,1-3.131,7.559Z"
        transform="translate(-647.108 -659.312)"
        fill={props.color}
      />
      <path
        id="Path_127"
        data-name="Path 127"
        d="M1100.635,1117.313a4.4,4.4,0,0,1-3.131-1.3l-7.977-7.977a4.428,4.428,0,0,1,6.262-6.262l7.977,7.977a4.428,4.428,0,0,1-3.131,7.559Z"
        transform="translate(-1044.836 -1057.082)"
        fill={props.color}
      />
      <path
        id="Path_128"
        data-name="Path 128"
        d="M549.928,943.326a4.428,4.428,0,1,1,0-8.856h11.665a4.428,4.428,0,1,1,0,8.856Z"
        transform="translate(-545.5 -904.398)"
        fill={props.color}
      />
      <path
        id="Path_129"
        data-name="Path 129"
        d="M1161.418,943.326a4.428,4.428,0,1,1,0-8.856H1172.7a4.428,4.428,0,0,1,0,8.856Z"
        transform="translate(-1108.131 -904.398)"
        fill={props.color}
      />
    </g>
  </svg>
)

export default IconLoading
